import { Field } from 'react-final-form';
import { ReactFinalFormSelect } from 'src/@core/components/mui/select';

const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgb(33 37 41 / 50%)' : 'none',
    border: '1px solid #212529',
    minHeight: '51px',
    '&:hover': {
      border: '1px solid #212529',
    },
    '&:active': {
      border: '1px solid #212529',
    },
    '&:focus': {
      border: '1px solid #212529',
    },
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: isFocused ? '#e7e5e3' : '#fff',
    color: '#333',
  }),
};

function Select({ name, label, className, options, required, isCreatable, async, shrink, multiple, ...other }) {
  return (
    <Field name={name} component="select">
      {({ input, meta }) => (
        // <div className={cx(className, css.root)}>
        //   {label && <label>{label}</label>}
        //   <ReactSelect required={required} options={options} {...input} {...other} styles={selectStyles} />
        //   {meta.touched && meta.error && <span className={css.error}>{meta.error}</span>}
        // </div>
        <>
          <ReactFinalFormSelect
            fullWidth={true}
            isClearable={true}
            label={label}
            options={options}
            shrink={shrink}
            required={required}
            {...input}
            {...meta}
            {...other}
            multiple={multiple}
            value={multiple ? input.value || [] : input.value}
            // value={currentProject?.uuid}
            // onChange={(value) => handleUpdateCurrentProject(value)}
            // getOptionLabel={(option) => option.name}
            // getOptionValue={(option) => option.uuid}
          />
        </>
      )}
    </Field>
  );
}

export default Select;
