import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Button, Checkbox, Grid, Link, Stack, styled, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'src/@core/components/mui/select';
import AddNewMember from 'src/components/add-new-member';
import DownloadBadgeButton from 'src/components/atoms/button/DownloadBadgeButton';
import DownloadTicketButton from 'src/components/atoms/button/DownloadTicketButton';
import IssueBadgeButton from 'src/components/atoms/button/IssueBadgeButton';
import SaveGiftsButton from 'src/components/atoms/button/SaveGiftsButton';
import ButtonLoading from 'src/components/button-loading';
import Loader from 'src/components/loader';
import { useAbility } from 'src/hooks/useAbility';
import { projectAPIs, userAPIs } from 'src/services';
import {
  blockUserFromSkud,
  editUser,
  editUserByProjectUuid,
  setProject,
  updateUserByProjectUuid,
} from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { getLocalizationValue } from 'src/utils/common';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import { downloadFile, request } from 'src/utils/request';
import { HeaderSection } from '.';
import QuotaByRole from './QuotaByRole';
import { ADD_MEMBER_METHODS_OPTIONS } from 'src/utils/constant';

const ProjectCheckBox = styled(Checkbox)(() => ({ marginRight: 8 }));

const PRIORITY_DOCUMENT_TYPE = {
  photo: 4,
  international_passport: 3,
  confirmation_letter: 2,
  invoice: 1,
};

function ProfileSettings({ userUuid, isBezviz, members, membersVizits, fetchMembers }) {
  const navigate = useNavigate();
  const ability = useAbility();
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const roles = useSelector((state) => state.application.roles);
  const projects = useSelector((state) => state.application.projects);
  const currentProject = useSelector((state) => state.application.currentProject);
  const isStaff = useSelector((state) => state.auth.isStaff);

  const projectUuid = currentProject?.uuid;
  const projectSettings = profileInfo.projectSettings?.[projectUuid];
  const joinedProjects = projects.filter((p) => profileInfo?.projectUuids?.includes(p.uuid));
  const mainRoleId = currentProject ? projectSettings?.mainRoleId : profileInfo.mainRoleId;
  const role = roles.find((role) => role.id === mainRoleId);
  const quota = projectSettings?.quota || 0;
  const quotaByRole = projectSettings?.quotaByRole || {};
  const isAccredited = projectSettings?.isAccredited;
  const isBadgePrinted = projectSettings?.isBadgePrinted;
  const isReprintBlocked = projectSettings?.isReprintBlocked;
  const isCurrentBadgeBlocked = projectSettings?.isCurrentBadgeBlocked;
  const addMemberMethod = projectSettings?.addMemberMethod;

  const initialValues = {
    projectUuids: projects.filter((p) => profileInfo.projectUuids.find((uuid) => uuid === p.uuid)),
    mainRoleId: role?.id || '',
  };

  const handleUpdateUser = async ({ isAccredited }) => {
    if (userUuid) {
      await updateUserByProjectUuid(userUuid, { isAccredited }, { isUpdateDocuments: true });
    }
  };

  const handleUpdateProject = (projects) => {
    const projectIds = projects.map((p) => p.uuid);
    if (currentProject && !projectIds.includes(currentProject.uuid)) {
      setProject(null);
    }
    editUser({ uuid: profileInfo.uuid, projectIds });
  };

  const handleUpdateMainRole = async (roleId) => {
    await updateUserByProjectUuid(profileInfo.uuid, { mainRoleId: roles.find((role) => role.id === roleId)?.id });
    eventBus.emit(EVENTS.RELOAD_PROFILE);
  };

  const handleUpdateAddMemberMethod = async (value) => {
    await updateUserByProjectUuid(profileInfo.uuid, { addMemberMethod: value });
    eventBus.emit(EVENTS.RELOAD_PROFILE);
  };

  const handleUpdateCurrentProject = (projectUuid) => {
    setProject(projects.find((project) => project.uuid === projectUuid));
  };

  const handleUpdateQuota = debounce((e) => {
    const value = parseInt(e.target.value);
    updateUserByProjectUuid(profileInfo.uuid, { quota: value });
  }, 500);

  const handleRemoveFromProject = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: 'Подтвердите удаление из этого проекта',
      content: `Вы уверены, что хотите удалить этого пользователя из этого проекта?`,
      onOk: async () => {
        try {
          await userAPIs.removeFromProject(profileInfo.uuid, projectUuid);
          createNotification('Удалить успешно', 'success');
          setTimeout(() => {
            navigate(`/projects/${projectUuid}/users`);
          }, 500);
        } catch (error) {
          console.error(error);
          createErrorNotification(error.message.error);
        }
      },
    });
  };

  const handleBlockBadge = async () => {
    if (!projectUuid || !userUuid) return;
    try {
      await blockUserFromSkud(projectUuid, userUuid);
      createNotification('Бейдж заблокирован', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const handleUnBlockReprintBadge = async () => {
    if (!projectUuid || !userUuid) return;
    try {
      await editUserByProjectUuid(projectUuid, userUuid, {
        isReprintBlocked: false,
      });
      createNotification('Бейдж перепечатки разблокирован', 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message.error);
    }
  };

  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canBlockUser = ability.can(ACCESSES.BLOCK_USER);
  const canChangeRole = ability.can(ACCESSES.CHANGE_ROLE);
  const canEditUser = ability.can(ACCESSES.EDIT_USER);
  const canPrintBadge = ability.can(ACCESSES.PRINT_BADGE);
  const canRepeatPrintBadge = ability.can(ACCESSES.REPEAT_PRINT_BADGE);
  const canBlockFromSkud = ability.can(ACCESSES.BLOCK_FROM_SKUD);
  const canUnblockReprintBadge = ability.can(ACCESSES.UNBLOCK_REPRINT_BADGE);

  return (
    <>
      <Stack>
        {(canAccrediteUser || canBlockUser) && <HeaderSection>действия</HeaderSection>}
        <Stack gap="16px">
          {canAccrediteUser && (
            <>
              <ButtonLoading variant="contained" onClick={() => handleUpdateUser({ isAccredited: true })}>
                подтвердить
              </ButtonLoading>
              <ButtonLoading variant="outlined" onClick={() => handleUpdateUser({ isAccredited: false })}>
                отказать
              </ButtonLoading>
            </>
          )}
          {canBlockUser && (
            <>
              <ButtonLoading variant="outlined" onClick={handleRemoveFromProject}>
                удалить из проекта
              </ButtonLoading>
            </>
          )}
          {isStaff === null ||
            (canBlockFromSkud && (
              <>
                <ButtonLoading variant="outlined" onClick={handleBlockBadge} disabled={isCurrentBadgeBlocked}>
                  Заблокировать бейдж
                </ButtonLoading>
              </>
            ))}
          {isReprintBlocked && (isStaff === null || canUnblockReprintBadge) && (
            <ButtonLoading variant="outlined" onClick={handleUnBlockReprintBadge}>
              Разблокировать бейдж перепечатки
            </ButtonLoading>
          )}
          {canPrintBadge && projectUuid && (
            <>
              <DownloadBadgeButton
                projectUuid={projectUuid}
                userUuid={userUuid}
                disabled={
                  !canPrintBadge || !isAccredited || (isBadgePrinted && !canRepeatPrintBadge) || isReprintBlocked
                }
                isAccredited={isAccredited}
                isCurrentBadgeBlocked={isCurrentBadgeBlocked}
                // onEditBadgePrinted={async (isBadgePrinted) => {
                //   await updateUserByProjectUuid(userUuid, {
                //     isBadgePrinted,
                //   });
                // }}
              />
              <DownloadTicketButton projectUuid={projectUuid} userUuid={userUuid} disabled={!canPrintBadge} />
              <SaveGiftsButton
                user={projectSettings}
                disabled={!canPrintBadge}
                onClick={async () => {
                  await editUserByProjectUuid(projectUuid, userUuid, { isGivenMerch: !projectSettings?.isGivenMerch });
                }}
              />
              <IssueBadgeButton
                user={projectSettings}
                disabled={!canPrintBadge}
                onClick={async () => {
                  await editUserByProjectUuid(projectUuid, userUuid, {
                    isBadgePrinted: !projectSettings?.isBadgePrinted,
                  });
                }}
              />
            </>
          )}
          {/* <Button
            variant="outlined"
            disabled={isDownloading}
            startIcon={isDownloading ? <Loader size="12px" color="grey" /> : <></>}
            onClick={handleDownloadBadge}
          >
            Скачать бейджа
          </Button> */}
        </Stack>
      </Stack>

      <Stack>
        <HeaderSection>проекты</HeaderSection>
        <Stack gap="16px">
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={projects}
            getOptionLabel={(option) => getLocalizationValue(option, 'fullName')}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <ProjectCheckBox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {getLocalizationValue(option, 'fullName')}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Все проекты" />}
            defaultValue={initialValues.projectUuids}
            onChange={(_, value) => handleUpdateProject(value)}
            disabled={!canEditUser}
          />
        </Stack>
      </Stack>

      <Stack>
        <HeaderSection>дополнительные данные</HeaderSection>
        <Stack gap="16px">
          {joinedProjects?.length > 0 && (
            <>
              <Select
                fullWidth={true}
                isClearable={true}
                label="Выбрать проект"
                options={joinedProjects}
                value={currentProject?.uuid}
                onChange={(value) => handleUpdateCurrentProject(value)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.uuid}
                disabled={!canChangeRole}
              />

              {currentProject && (
                <>
                  <Grid container spacing={4}>
                    {/* <Grid item xs={profileInfo?.accreditedBy === '' ? 8 : 12}> */}
                    <Grid item xs={12}>
                      <Select
                        fullWidth={true}
                        label="Роль"
                        options={roles}
                        value={role?.id}
                        getOptionLabel={(item) => item.name?.ru_RU}
                        getOptionValue={(item) => item.id}
                        onChange={(value) => handleUpdateMainRole(value)}
                        disabled={!canChangeRole}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        fullWidth={true}
                        label="Метод добавления участников"
                        options={ADD_MEMBER_METHODS_OPTIONS}
                        value={addMemberMethod}
                        getOptionLabel={(item) => item.label}
                        getOptionValue={(item) => item.value}
                        onChange={(value) => handleUpdateAddMemberMethod(value)}
                      />
                    </Grid>
                    {profileInfo?.accreditedBy === '' && (
                      <Grid item xs={12}>
                        <Form
                          onSubmit={() => {}}
                          render={() => (
                            <QuotaByRole userUuid={userUuid} quotaByRole={quotaByRole} canEditUser={canEditUser} />
                          )}
                        ></Form>
                      </Grid>
                    )}
                    {/* {profileInfo?.accreditedBy === '' && (
                      <Grid item xs={4}>
                        <TextField type="number" defaultValue={quota} onChange={handleUpdateQuota} />
                      </Grid>
                    )} */}
                  </Grid>
                </>
              )}
            </>
          )}

          {/* {membersVizits.length > 0 && (
            <Button
              variant="contained"
              onClick={() => eventBus.emit(EVENTS.OPEN_VIZIT_SEARCH_MODAL, currentProject?.uuid)}
            >
              Поиск визит
            </Button>
          )} */}

          {/* {currentProject?.uuid && profileInfo?.accreditedBy === '' && (
            <AddNewMember
              quota={quota}
              members={members}
              userUuid={profileInfo.uuid}
              projectUuid={currentProject?.uuid}
              mainRoleId={mainRoleId}
              onReloadMembers={() => fetchMembers(userUuid)}
            />
          )} */}
        </Stack>
      </Stack>
    </>
  );
}

export default ProfileSettings;
