import { CalendarMonth } from '@mui/icons-material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Box, Button, Card, IconButton, Stack, Typography } from '@mui/material';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import Countdown from 'react-countdown';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLanguage, useResponsive } from 'src/hooks';
import useProjectImage from 'src/hooks/useProjectImage';
// import { routes } from 'src/utils/routes';
import { getLocalizationValue } from '../../utils/common';
import { LOCALIZATION, TEXT } from '../../utils/constant';
// import ButtonMyTicket from '../button-my-ticket';
// import JoinProjectButton from '../join-project-button';
// import UserRole from '../user-role';
// import UserStatus from '../user-status';
import classes from './project-card.module.sass';

function ProjectCard({ langue, project, fullWidth, showProjectDetail, className }) {
  const ref = useRef();
  const navigate = useNavigate();
  const isDesktop = useResponsive();
  const { lang: langApp } = useLanguage();
  const lang = langue || langApp;

  useEffect(() => {
    moment.locale(lang);
    return () => {
      moment.locale('ru');
    };
  }, []);

  // const user = useSelector((state) => state.auth.user);
  // const roles = useSelector((state) => state.app.roles);
  // const order = useSelector((state) => state.app.order);
  const { mainPhoto } = useProjectImage({ project });

  const projectUuid = project.uuid;
  // const isJoinedProject = user?.projectUuids?.includes(projectUuid);
  // const role = roles.find((role) => role.id === user?.projectSettings?.[projectUuid]?.mainRoleId);
  // const numOfPackages =
  //   order?.find((item) => item.projectUuid === projectUuid)?.packages?.reduce((p, c) => (p += c.count), 0) || 0;
  // const price = project.price?.['643']?.priceFrom ?? 0;
  // const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  // const readyToAccreditate = user?.projectSettings?.[projectUuid]?.readyToAccreditate;

  const gotoShoppingCartProject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // navigate(routes.orderDetail.path.replace(':projectUuid', projectUuid));
  };

  const getRemainingNumDay = (installationDatePlan) => {
    const result = moment.unix(installationDatePlan).diff(moment(), 'd');
    if (result < 0) {
      return <span className={classes.text}>{TEXT[lang].ALREADY_STARTED}&nbsp;</span>;
      // return <Chip label={TEXT[lang].ALREADY_STARTED} color="success"></Chip>;
    }
    if (result < 1) {
      return (
        <>
          <span className={classes.text}>{TEXT[lang].UNTIL_START}&nbsp;</span>
          <span className={classes.date}>
            <Countdown date={installationDatePlan * 1000} renderer={rendererCountdown} />
          </span>
        </>
      );
    }
    return (
      <>
        <span className={classes.text}>{TEXT[lang].UNTIL_START}&nbsp;</span>
        <span className={classes.date}>{result}&nbsp;</span>
        <span className={classes.text}>{TEXT[lang].DAYS}&nbsp;</span>
      </>
    );
  };

  const handleGotoProject = () => {
    // navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid));
  };

  const rendererCountdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {parseInt(hours).toString().padStart(2, '0')}:{parseInt(minutes).toString().padStart(2, '0')}:
          {parseInt(seconds).toString().padStart(2, '0')}
        </span>
      );
    }
  };

  const renderCountDown = () => {
    if (!project.dateStartPlan) return;
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{ color: 'white', background: '#bc95f1 !important', borderRadius: '30px' }}
      >
        {showProjectDetail ? (
          <>
            {moment
              .unix(project.dateStartPlan)
              .format(
                moment.unix(project.dateStartPlan).isSame(moment.unix(project.dateFinishPlan), 'month')
                  ? 'DD'
                  : 'DD MMMM'
              )}
            {' - '}
            {moment.unix(project.dateFinishPlan).format('DD MMMM')}
          </>
        ) : (
          <>{getRemainingNumDay(project.dateStartPlan)}</>
        )}
      </Button>
    );
  };

  // const { height } = useProjectHeight({ ref });

  return (
    <Card
      className={classNames(classes.projectCard, className)}
      ref={ref}
      sx={{ mr: { xs: 0, md: fullWidth ? '0px' : '1rem' }, borderRadius: '30px' }}
      onClick={handleGotoProject}
    >
      {mainPhoto ? (
        <LazyLoad>
          <div className={`${showProjectDetail ? classes.backgroundImageProjectDetail : classes.backgroundImage}`}>
            <img src={mainPhoto} width="100%" />
          </div>
        </LazyLoad>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          className={classes.backgroundImage}
          sx={{ background: '#d5d5d5 !important', minHeight: '280px' }}
        >
          <CalendarMonth sx={{ fontSize: '100px', color: 'gray' }} />
        </Stack>
      )}
      <Stack className={classes.wrapper} justifyContent="space-between" gap="24px">
        {/* <Stack direction="row" justifyContent="space-between" className={classes.top}>
          <Box>
            <JoinProjectButton
              project={project}
              redirectUrl={showProjectDetail ? routes.homepage.path : ''}
              size={isDesktop ? 'medium' : 'small'}
            />
          </Box>
          {isJoinedProject && showProjectDetail && (
            <Badge
              badgeContent={numOfPackages}
              sx={{
                '& .MuiBadge-badge': {
                  background: '#BC95F1',
                  color: '#FFF',
                  marginTop: '5px',
                  marginRight: '5px',
                },
              }}
            >
              <IconButton sx={{ background: '#FFF' }} size="large" onClick={gotoShoppingCartProject}>
                <ShoppingCartIcon sx={{ color: '#BC95F1' }} />
              </IconButton>
            </Badge>
          )}
        </Stack> */}
        <Stack
          className={classes.groupBtn}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          gap="8px"
          flexWrap="wrap"
        >
          {renderCountDown()}
          <Stack gap="8px" alignItems="flex-end">
            {/* <UserRole role={role} sx={{ alignItems: 'center', display: 'flex' }} />
              <UserStatus
                isAccredited={isAccredited}
                readyToAccreditate={readyToAccreditate}
                sx={{ height: '30px', alignItems: 'center', display: 'flex' }}
              /> */}
            {/* {isAccredited && (
                <ButtonMyTicket userUuid={user.uuid} projectUuid={projectUuid} size={isDesktop ? 'medium' : 'small'} />
              )} */}
          </Stack>
        </Stack>
        <Stack gap="4px" className={classes.desc}>
          <Typography className={classes.name} sx={{ whiteSpace: showProjectDetail ? 'normal' : 'nowrap' }}>
            {getLocalizationValue(project, 'fullName', lang) || project.name}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

export default ProjectCard;
