import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ButtonLoading from 'src/components/button-loading';
import Loader from 'src/components/loader';
import { projectAPIs } from 'src/services';
import { LOCALIZATION, MESSAGES } from 'src/utils/localization';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

const BDK_OPTIONS = {
  withTable: { value: 'with_table', label: 'Со столом' },
  withoutTable: { value: 'without_table', label: 'Без стола' },
};

const ALLOW_SLOT_OPTIONS = {
  allow: { value: true, label: 'да' },
  deny: { value: false, label: 'Нет' },
};

const PROCESS_BDK_OPTIONS = {
  bdk: { value: 'bdk', label: 'БДК' },
  networking: { value: 'networking', label: 'Нетворкинг' },
};

function BDKSettings({ projectUuid }) {
  const fetchBDKSettings = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await projectAPIs.getBDKSetting(projectUuid);
    return response.message;
  };

  const { isLoading, data, refetch, isRefetching } = useQuery(['fetchBDKSettings', projectUuid], fetchBDKSettings, {
    fetchBDKSettings: !!projectUuid,
  });

  const [currentOption, setCurrentOption] = useState({
    allowIsBusySlot: ALLOW_SLOT_OPTIONS.deny.value,
    typeBDK: BDK_OPTIONS.withTable.value,
    typeProcessBDK: PROCESS_BDK_OPTIONS.bdk.value,
  });

  useEffect(() => {
    if (data) {
      setCurrentOption(data);
    }
  }, [data]);

  const handleChangeOption = (option, value) => {
    setCurrentOption((old) => ({ ...old, [option]: value }));
  };

  const handleUpdateBDK = async () => {
    try {
      await projectAPIs.updateBDKSetting(projectUuid, {
        typeBDK: currentOption.typeBDK,
        allowIsBusySlot: currentOption.allowIsBusySlot === 'true',
        typeProcessBDK: currentOption.typeProcessBDK,
      });
      refetch();
      createNotification(MESSAGES[LOCALIZATION.ru_RU].UPDATE_SUCCESSFULLY, 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[LOCALIZATION.ru_RU].ERROR);
    }
  };

  return (
    <Card>
      <CardContent>
        {isLoading || isRefetching ? (
          <Loader />
        ) : (
          <Grid container>
            <Grid item xs={12} md={6}>
              <Stack gap="18px">
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Выберите тип BDK, пожалуйста:</FormLabel>
                  <RadioGroup value={currentOption?.typeBDK} name="bdk-setting">
                    {Object.values(BDK_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('typeBDK', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {/* 
                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Разрешить забронировать занятый слот:</FormLabel>
                  <RadioGroup value={currentOption?.allowIsBusySlot} name="bdk-setting">
                    {Object.values(ALLOW_SLOT_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('allowIsBusySlot', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}

                <FormControl>
                  <FormLabel sx={{ marginBottom: '4px' }}>Выберите вариант проведения БДК (БДК/Нетворкинг):</FormLabel>
                  <RadioGroup value={currentOption?.typeProcessBDK} name="bdk-setting">
                    {Object.values(PROCESS_BDK_OPTIONS).map((option) => (
                      <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        onClick={(e) => handleChangeOption('typeProcessBDK', e.target.value)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <ButtonLoading
                  variant="contained"
                  sx={{ width: { xs: '100%', md: '150px' } }}
                  disabled={
                    data?.typeBDK === currentOption?.typeBDK &&
                    data?.allowIsBusySlot === currentOption?.allowIsBusySlot &&
                    data?.typeProcessBDK === currentOption?.typeProcessBDK
                  }
                  onClick={handleUpdateBDK}
                >
                  сохранить
                </ButtonLoading>
              </Stack>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default BDKSettings;
