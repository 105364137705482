import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { IMAGE_FILE_TYPES, LOCALIZATION } from 'src/constants';
import { EVENTS, LANGUAGES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { TableHeader } from '.';
import classes from './data.module.sass';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { toast } from 'react-toastify';
import { request } from 'src/utils/request';
import { setProject } from 'src/store/actions/application';
import classNames from 'classnames';
import Loader from 'src/components/loader';
import { createNotification } from 'src/utils/notifications';

function Photos({ projectUuid, refetchProjectDetail }) {
  const currentProject = useSelector((state) => state.application.currentProject);
  const projectPhotos = currentProject.photos || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeSubmitting, setTypeSubmitting] = useState('');

  const PHOTOS = [
    {
      key: 'logo',
      title: 'Логотип',
      body: 'Здесь небольшой текст для описания требований к изображению',
      recommend: '150x150 px',
      ratio: '1/1',
      width: 128,
      height: 128,
    },
    {
      key: 'mobile_small',
      title: 'Мобильный (маленький)',
      body: 'Здесь небольшой текст для описания требований к изображению',
      recommend: '400x400 px',
      ratio: '1/1',
      width: 200,
      height: 200,
    },
    {
      key: 'mobile_big',
      title: 'Мобильный (большой)',
      body: 'Здесь небольшой текст для описания требований к изображению',
      recommend: '900x200 px',
      ratio: '9/2',
      width: 405,
      height: 90,
    },
    {
      key: 'web_image',
      title: 'Веб-изображение',
      body: 'Здесь небольшой текст для описания требований к изображению',
      recommend: '1350x300 px',
      ratio: '9/2',
      width: 405,
      height: 90,
    },
    {
      key: 'web_big',
      title: 'Веб-изображение (большой)',
      body: 'Здесь небольшой текст для описания требований к изображению',
      recommend: '1800x400 px',
      ratio: '9/2',
      width: 405,
      height: 90,
    },
  ];

  const handleUploadProjectPhoto = async (key, photo) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      setTypeSubmitting(key);
      let body = new FormData();
      body.append('typePhoto', key);
      body.append('filePhoto', photo);
      await request(`/internal/projects/${projectUuid}/photos`, { method: 'PUT', body });
      refetchProjectDetail();
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    } finally {
      setIsSubmitting(false);
      setTypeSubmitting('');
    }
  };

  return (
    <Stack gap="20px" marginTop="15px" marginBottom="35px">
      <TableHeader>изображения</TableHeader>
      <Grid container spacing={7.5}>
        {PHOTOS.map((photo) => (
          <Grid key={photo.key} item xs={12}>
            <Stack direction={{ md: 'row', xs: 'column' }} gap="12px">
              {typeSubmitting === photo.key && isSubmitting ? (
                <Box className={classes.largePhoto}>
                  <Loader />
                </Box>
              ) : (
                <>
                  {currentProject.photos?.[photo.key]?.length > 0 ? (
                    <>
                      <input
                        // ref={inputFileRef}
                        accept="image/jpg, image/jpeg, image/png"
                        className={classes.inputAvatar}
                        id={`photo-${photo.key}`}
                        multiple={false}
                        type="file"
                        onChange={(e) => handleUploadProjectPhoto(photo.key, e.target?.files?.[0])}
                      />

                      <label htmlFor={`photo-${photo.key}`} style={{ width: 405 }}>
                        <img
                          src={currentProject.photos?.[photo.key][0] + `?dummy=${Date.now()}`}
                          className={classNames(classes.image)}
                          alt="logo"
                          style={{ aspectRatio: photo.ratio, width: photo.width, height: photo.height }}
                        />
                      </label>
                    </>
                  ) : (
                    <div style={{ width: 405 }}>
                      <FileUploader
                        types={IMAGE_FILE_TYPES}
                        children={
                          <Box
                            className={classNames(classes.uploadZone)}
                            sx={{ aspectRatio: photo.ratio, width: photo.width, height: photo.height }}
                          >
                            <AddPhotoAlternateIcon />
                            <Typography>загрузить логотип</Typography>
                          </Box>
                        }
                        handleChange={(avatar) => handleUploadProjectPhoto(photo.key, avatar)}
                        name="file"
                      />
                    </div>
                  )}
                </>
              )}

              <Stack gap="8px">
                <Stack direction="row" gap="4px" alignItems="center">
                  <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.primary, fontWeight: 600 }}>
                    {photo.title}
                  </Typography>
                  <Typography variant="caption">({photo.recommend})</Typography>
                </Stack>
                {photo.body && <Typography variant="caption">{photo.body}</Typography>}
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default Photos;
