import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import AnketaForm from './AnketaForm';
import { Close, ModeEdit } from '@mui/icons-material';
import eventBus, { EVENTS } from 'src/utils/eventBus';
import { useLanguage } from 'src/hooks';
import { editUser } from 'src/store/actions/application';
import { useEffect, useState } from 'react';
import { generalAPIs } from 'src/services';
import Loader from '../loader';
import { getLocalizationValue } from 'src/utils/common';
import { LOCALIZATION } from 'src/utils/localization';

function EditPartAnketaModal({ projectUuid, user, open, onClose, onReloadTable }) {
  const { localizeText, localizeMessage } = useLanguage();

  const [isInit, setIsInit] = useState(false);
  const [autoFillTemplate, setAutoFillTemplate] = useState();

  const projectSettings = user?.projectSettings?.[projectUuid];
  const isLocked = projectSettings?.isLocked;

  const handleForceEdit = (memberUuid) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: localizeText.CONFIRM,
      content: `${localizeMessage.FORCE_EDIT_CONFIRM}`,
      onOk: async () => {
        await editUser({
          uuid: memberUuid,
          ...{ forceEdit: true, currentProjectUuid: projectUuid },
        });
      },
    });
  };

  useEffect(() => {
    const fetchAutoFillFields = async () => {
      try {
        const response = await generalAPIs.getAutoFillFields();
        setAutoFillTemplate(response.message);
      } catch (error) {
        console.error(error);
      } finally {
        setIsInit(true);
      }
    };
    fetchAutoFillFields();
  }, []);

  if (!user) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack gap="24px" alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6">Регистрация</Typography>
          <Stack direction="row" gap="16px">
            {isLocked && (
              <IconButton
                color="primary"
                sx={{ border: '1px solid', borderRadius: '4px' }}
                onClick={() => handleForceEdit(user?.uuid)}
              >
                <ModeEdit />
              </IconButton>
            )}
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {!isInit ? (
          <Loader />
        ) : (
          <AnketaForm
            user={user}
            projectUuid={projectUuid}
            isLocked={isLocked}
            autoFillTemplate={autoFillTemplate}
            isLockedField={(field) => {
              const fieldSettings = field?.AnketaFieldSettings ?? {};
              return !['Фамилия', 'Имя', 'Отчество', 'Название компании'].some((item) => {
                return (
                  item.toLowerCase() === fieldSettings.name?.toLowerCase?.() ||
                  item.toLowerCase() === getLocalizationValue(fieldSettings, 'name')?.toLowerCase?.() ||
                  item.toLowerCase() ===
                    getLocalizationValue(fieldSettings, 'name', LOCALIZATION.en_US)?.toLowerCase?.()
                );
              });
            }}
            onReloadMembers={() => {
              onReloadTable();
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EditPartAnketaModal;
