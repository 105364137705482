import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOCALIZATION, routes } from 'src/constants';
import CustomChip from 'src/@core/components/mui/chip';
import { Check, Close, Info, Link } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import { getComparator, TableSortLabel } from 'src/components/mui-table';
import { HOST_URL, ORDER_DIRECTION } from 'src/utils/constant';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function RoleTable({ projectUuid, projectStatistics }) {
  const navigate = useNavigate();
  const roles = useSelector((state) => state.application.roles);
  const list = roles;
  const count = list.length;

  const headCells = [
    { id: 'displayName', label: 'Роль', align: 'left', isSortable: true },
    { id: 4, label: 'ссылка', width: '100px' },
    { id: 2, label: 'анкета', width: '100px' },
    { id: 3, label: 'билет', width: '100px' },
  ];

  const rolesWithAnketa = projectStatistics.rolesWithAnketa || [];
  const rolesWithTicket = projectStatistics.rolesWithTicket || [];

  function splitArray(arr) {
    const length = arr.length;
    const midpoint = Math.ceil(length / 2);

    const firstArray = arr.slice(0, midpoint);
    const secondArray = arr.slice(midpoint);

    return [firstArray, secondArray];
  }

  const [order, setOrder] = useState(ORDER_DIRECTION.ASC);
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === ORDER_DIRECTION.ASC;
    const isDesc = orderBy === property && order === ORDER_DIRECTION.DESC;
    setOrder(isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC);
    setOrderBy(property);
  };

  const renderTable = (list, count) => {
    return (
      <TableContainer
        id={uniqueId('project-roles-table')}
        component={Paper}
        sx={{ position: 'relative', marginBottom: '20px' }}
      >
        <Table size="medium" sx={{ minHeight: 150 }}>
          <TableHead>
            <TableRow sx={{ background: '#F9FAFC' }}>
              {headCells.map((headCell, indexHead) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  width={headCell.width}
                  sx={{ position: 'relative' }}
                >
                  <Stack direction="row" justifyContent={headCell.align === 'left' ? 'flex-start' : 'center'}>
                    {headCell.isSortable ? (
                      <TableSortLabel
                        headCell={headCell}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                        {headCell.label}
                      </Typography>
                    )}
                    {indexHead < headCells.length - 1 && (
                      <Stack
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          height: '100%',
                        }}
                      >
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            width: '2px',
                            height: '14px !important',
                            margin: 'auto 0',
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {count === 0 ? (
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <Typography align="center">Нет данных</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {list.map((row, index) => {
                  const isInAnketa = rolesWithAnketa.includes(row.id);
                  const isInTicket = rolesWithTicket.includes(row.id);

                  return (
                    <TableRow hover tabIndex={-1} key={row.name + index}>
                      <TableCell component="th" scope="row" padding="none" sx={{ minWidth: '60%' }}>
                        <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold', paddingY: '12px' }}>
                          {row.name[LOCALIZATION.ru_RU]}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" sx={{ textAlign: 'center' }}>
                        <IconButton
                          onClick={() => {
                            try {
                              navigator.clipboard.writeText(
                                `${HOST_URL}/register?projectUuid=${projectUuid}&roleUuid=${row.id}`
                              );
                              createNotification('Скопировано в буфер обмена', 'success');
                            } catch (error) {
                              console.error(error);
                              createErrorNotification('Что-то пошло не так', 'error');
                            }
                          }}
                        >
                          <Link />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" gap="4px" justifyContent="center">
                          <Tooltip title="Перейдите в настройки">
                            <IconButton
                              onClick={() =>
                                window.open(
                                  routes.projectSettings.path.replace(':uuid', projectUuid) +
                                  `?tab=anketa&currentRole=${row.id}`,
                                  '_blank'
                                )
                              }
                            >
                              {isInAnketa ? <Check color="success" /> : <Close color="error" />}
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" gap="4px" justifyContent="center">
                          <Tooltip title="Перейдите в настройки">
                            <IconButton
                              onClick={() =>
                                window.open(
                                  routes.projectSettings.path.replace(':uuid', projectUuid) +
                                  `?tab=ticket&currentRole=${row.id}`,
                                  '_blank'
                                )
                              }
                            >
                              {isInTicket ? <Check color="success" /> : <Close color="error" />}
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Card>
      <CardHeader title="Настройка статистики" />
      <CardContent>
        <Stack direction={{ xs: 'column', md: 'row' }} gap="24px">
          {splitArray(
            list
              .slice()
              .map((item) => ({ ...item, displayName: item.name[LOCALIZATION.ru_RU] }))
              .sort(getComparator(order, orderBy))
          ).map((res, i) => (
            <React.Fragment key={i}>{renderTable(res, res.length)}</React.Fragment>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default RoleTable;
