import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker';
import PreviewAnketaForm from './PreviewAnketaForm';
import ImageCropperModal from '../modal/ImageCropperModal';

function PreviewAnketaModal({ projectUuid, anketaData, open, onClose }) {
  if (!anketaData) return null;
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack gap="24px" alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="h6">Регистрация</Typography>
          <Stack direction="row" gap="16px">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DatePickerWrapper>
          <PreviewAnketaForm projectUuid={projectUuid} data={anketaData} />
        </DatePickerWrapper>
        <ImageCropperModal isReadOnly />
      </DialogContent>
    </Dialog>
  );
}

export default PreviewAnketaModal;
