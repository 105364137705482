import { Box, FormControl, Grid, InputLabel, Select, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getFullName, getLocalizationValue } from 'src/utils/common';
import { getFullDate } from 'src/utils/dates';

const DEFAULT_FIELD_VALUE = '-';

const ProfileField = ({ label, value, values, renderValues }) => {
  const labelId = _.uniqueId('select-label__');

  return values && _.isArray(values) && values.length > 0 ? (
    <FormControl>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select labelId={labelId} label={label} readOnly multiple value={values || []} renderValue={renderValues}>
        {/* {values.map((value) => (
          <MenuItem key={value} value={value}>
            - {value.name}
          </MenuItem>
        ))} */}
      </Select>
    </FormControl>
  ) : (
    <TextField label={label} value={value || DEFAULT_FIELD_VALUE} InputLabelProps={{ shrink: true }} />
  );
};

function Profile({ userUuid, members }) {
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const roles = useSelector((state) => state.application.roles);
  const projects = useSelector((state) => state.application.projects);
  const countries = useSelector((state) => state.application.countries);
  const currentProject = useSelector((state) => state.application.currentProject);

  const projectSettings = profileInfo.projectSettings?.[currentProject?.uuid];
  const joinedProjects = projects.filter((p) => profileInfo?.projectUuids?.includes(p.uuid));
  const mainRoleId = currentProject ? projectSettings?.mainRoleId : profileInfo.mainRoleId;
  const role = roles.find((role) => role.id === mainRoleId);

  let companyCountry = countries.find((c) => c.code === parseInt(profileInfo.companyCountry, 10))?.localization?.ru_RU
    ?.name;

  let passportNumber = profileInfo.passportNumber;
  if (!passportNumber) {
    passportNumber = profileInfo.properties?.passport?.id + profileInfo.properties?.passport?.number;
  }
  if (!passportNumber) {
    passportNumber = profileInfo.properties?.document_number;
  }

  return (
    <Grid container rowSpacing={5} columnSpacing={6.75}>
      <Grid item xs={12} md={6}>
        <Stack gap="20px">
          {/* <ProfileField label="ФИО" value={getFullName(profileInfo)} /> */}
          <ProfileField label="Дата рождения" value={getFullDate(profileInfo.birthday)} />
          <ProfileField label="Телефон" value={profileInfo.phone} />
          <ProfileField label="Email" value={profileInfo.email} />
          <ProfileField label="Страна проживания" value={profileInfo.citizenshipName?.ru_RU} />
          <ProfileField
            label="Город работы"
            value={
              profileInfo.properties?.affiliation?.city || profileInfo.properties?.legalFormCity || profileInfo.city
            }
          />
          <ProfileField
            label="Почтовый индекс"
            value={profileInfo.properties?.affiliation?.zipCode || profileInfo.properties?.legalFormIndex}
          />
          <ProfileField label="Серия и номер паспорта" value={passportNumber} />
          <ProfileField label="Дата выдачи" value={getFullDate(profileInfo.properties?.passport?.expirationDate)} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack gap="20px">
          {profileInfo.isAutoAccreditated !== undefined && (
            <ProfileField label="Автоакредитеция" value={profileInfo.isAutoAccreditated} />
          )}
          <ProfileField
            label="Страна работы"
            value={
              profileInfo.properties?.affiliation?.country || profileInfo.properties?.legalFormCountry || companyCountry
            }
          />
          <ProfileField
            label="Адрес работы"
            value={profileInfo.properties?.affiliation?.address || profileInfo?.city || profileInfo.companyAddress}
          />
          <ProfileField
            label="Название компании"
            value={
              profileInfo.properties?.affiliation?.companyName ||
              profileInfo.companyName ||
              profileInfo.properties?.companyName
            }
          />
          <ProfileField label="Должность" value={role?.name?.ru_RU || DEFAULT_FIELD_VALUE} />

          <ProfileField
            label="Проекты"
            values={joinedProjects}
            renderValues={(values) => {
              return (
                <Box>
                  {values.map((value) => (
                    <Box key={_.uniqueId('select-value__')}>- {getLocalizationValue(value, 'fullName')}</Box>
                  ))}
                </Box>
              );
            }}
          />
          <ProfileField label="Дата регистрации" value={getFullDate(profileInfo?.createdAt)} />
          <ProfileField label="Теги" value={profileInfo?.roleIds[0]} />
          {profileInfo?.accreditedBy === '' && (
            <ProfileField
              label="Участники"
              values={members}
              renderValues={(values) => (
                <>
                  {!values?.length && '-'}
                  {(values || []).map((p, iP) => (
                    <div>
                      <a key={iP} href={`/profile/${p.uuid}`} target="_blank">
                        - {p.firstName} {p.lastName} {p.middleName}
                      </a>
                      <br></br>
                    </div>
                  ))}
                </>
              )}
            />
          )}
          {profileInfo?.accreditedBy !== '' && <ProfileField label="Добавлен" value={profileInfo?.accreditedBy} />}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Profile;
