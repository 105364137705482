import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Typography } from '@mui/material';
import MuiSelect from '@mui/material/Select';
import _ from 'lodash';

function Select({
  fullWidth,
  label,
  value,
  defaultValue,
  isClearable,
  shrink,
  options = [],
  onChange = () => {},
  getOptionValue = (option) => option,
  getOptionLabel = (option) => option,
  renderValue,
  error,
  errorComponent,
  multiple,
  ...resProps
}) {
  const labelId = _.uniqueId('select-label__');

  console.log('value', value);
  return (
    <FormControl error={error} fullWidth={fullWidth}>
      <InputLabel shrink={shrink} id={labelId} sx={{ color: !shrink && 'rgba(58, 53, 65, 0.38)' }}>
        {label}
      </InputLabel>
      <MuiSelect
        multiple={multiple}
        notched={shrink}
        labelId={labelId}
        label={label}
        defaultValue={defaultValue}
        value={multiple ? value || [] : value || ''}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          '& .MuiSelect-select': isClearable
            ? {
                paddingRight: '4px !important',
              }
            : {},
        }}
        endAdornment={
          (multiple ? value?.length > 0 : value) && isClearable ? (
            <IconButton
              disabled={resProps.disabled}
              onClick={() => onChange(multiple ? [] : '')}
              sx={{ marginRight: '14px' }}
            >
              <ClearIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
        {...resProps}
      >
        {options?.length === 0 && (
          <Typography variant="body2" sx={{ textAlign: 'center', padding: '8px' }}>
            No options
          </Typography>
        )}
        {options?.map((option, i) => (
          <MenuItem key={`${labelId}-${i}`} value={getOptionValue(option)}>
            {renderValue ? renderValue(option) : getOptionLabel(option)}
          </MenuItem>
        ))}
      </MuiSelect>
      {errorComponent ? errorComponent : !!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

export function ReactFinalFormSelect({
  fullWidth,
  label,
  value,
  defaultValue,
  isClearable,
  shrink = true,
  options = [],
  onChange = () => {},
  getOptionValue = (option) => option,
  getOptionLabel = (option) => option,
  renderValue,
  required,
  multiple,
  error,
  ...resProps
}) {
  const labelId = _.uniqueId('select-label__');

  return (
    <FormControl fullWidth={fullWidth} required={required} error={!!error}>
      <InputLabel shrink={shrink ? shrink : undefined} id={labelId} sx={{ color: !shrink && 'rgba(58, 53, 65, 0.38)' }}>
        {label}
      </InputLabel>
      <MuiSelect
        multiple={multiple}
        required={required}
        notched={shrink ? shrink : undefined}
        labelId={labelId}
        label={label}
        defaultValue={defaultValue}
        value={multiple ? value || [] : value || ''}
        onChange={onChange}
        sx={{
          '& .MuiSelect-select': isClearable
            ? {
                paddingRight: '4px !important',
              }
            : {},
        }}
        endAdornment={
          (multiple ? value?.length > 0 : value) && isClearable ? (
            <IconButton onClick={() => onChange(multiple ? [] : '')} sx={{ marginRight: '14px' }}>
              <ClearIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
        // {...resProps}
      >
        {options?.length === 0 && (
          <Typography variant="body2" sx={{ textAlign: 'center', padding: '8px' }}>
            No options
          </Typography>
        )}
        {options?.map((option, i) => (
          <MenuItem key={`${labelId}-${i}`} value={getOptionValue(option)}>
            {renderValue ? renderValue(option) : getOptionLabel(option)}
          </MenuItem>
        ))}
      </MuiSelect>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

export function SelectHookForm({
  shrink = true,
  required,
  label,
  error,
  multiple,
  disabled,
  selectProps = {},
  errorComponent = <></>,
  renderValue,
  children,
  margin = 'none',
  variant,
  sx = {},
}) {
  const labelId = _.uniqueId('hook-form-select-label__');
  return (
    <FormControl margin={margin} fullWidth error={error} disabled={disabled} sx={sx}>
      <InputLabel variant={variant} required={required} id={labelId} shrink={shrink ? shrink : undefined}>
        {label}
      </InputLabel>
      <MuiSelect
        {...selectProps}
        multiple={multiple}
        label={label}
        variant={variant}
        labelId={labelId}
        notched={shrink ? shrink : undefined}
        renderValue={renderValue}
      >
        {children}
      </MuiSelect>
      {errorComponent}
    </FormControl>
  );
}

export function SelectControllerHookForm({
  shrink = true,
  required,
  label,
  error,
  disabled,
  fieldForm = {},
  errorComponent = <></>,
  children,
}) {
  const labelId = _.uniqueId('hook-form-select-label__');
  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <InputLabel required={required} id={labelId} shrink={shrink ? shrink : undefined}>
        {label}
      </InputLabel>
      <MuiSelect
        value={fieldForm?.value}
        onChange={(e) => fieldForm?.onChange(e.target.value)}
        label={label}
        labelId={labelId}
        notched={shrink ? shrink : undefined}
        disabled={disabled}
      >
        {children}
      </MuiSelect>
      {errorComponent}
    </FormControl>
  );
}

export const renderMultiSelect = (selected) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, flexDirection: 'column' }}>
    {selected.map((value) => (
      <div
        key={value}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          overflowWrap: 'anywhere',
          width: '100%',
        }}
      >
        {value}
      </div>
    ))}
  </Box>
);

export default Select;
