import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { emailTemplateAPIs, generalAPIs } from 'src/services';
import classes from './email.module.sass';
import TemplateCreate from './TemplateCreate';
import TemplateForm, { FOR_EVENTS_OPTIONS } from './TemplateForm';
import { MUITable } from 'src/components/mui-table';
import { Add } from '@mui/icons-material';

function EmailSettings({ projectUuid }) {
  const [currentRole, setCurrentRole] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [showAddNewTemplate, setShowAddNewTemplate] = useState(false);

  const fetchRoles = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await generalAPIs.getRoles(projectUuid);
    return res.message;
  };

  const fetchEmailTemplate = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid] = queryKey;
    const res = await emailTemplateAPIs.getEmailTemplate(projectUuid, roleUuid);
    return res.message;
  };

  const { isLoading: isLoadingRoles, data: roles } = useQuery(['fetchRoles', projectUuid], fetchRoles, {
    enabled: !!projectUuid,
  });

  const {
    isLoading: isLoadingTemplate,
    data: templates,
    isFetched: isFetchedTemplate,
    isFetching: isFetchingTemplate,
    refetch: refetchData,
  } = useQuery(['fetchEmailTemplate', projectUuid, currentRole], fetchEmailTemplate, {
    enabled: !!projectUuid && !!currentRole,
  });

  const notFoundTemplate = isFetchedTemplate && !templates?.length;

  if (isLoadingRoles) return <Loader />;
  return (
    <Card className={classes.root}>
      <CardContent>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
            Для роли:
          </Typography>
          <Select
            fullWidth={true}
            label="Выберите роль"
            options={roles}
            value={currentRole}
            getOptionLabel={(item) => item.name?.ru_RU}
            getOptionValue={(item) => item.id}
            onChange={(value) => setCurrentRole(value)}
          />
        </Stack>
      </CardContent>

      <Card>
        <CardContent>
          {isLoadingTemplate ? (
            <Loader />
          ) : (
            <Stack paddingY="42px">
              {!currentRole && <Typography align="center">Выберите роль, чтобы продолжить</Typography>}
              {currentRole && (
                <>
                  {notFoundTemplate ? (
                    <TemplateCreate projectUuid={projectUuid} roleUuid={currentRole} onRefetchData={refetchData} />
                  ) : selectedTemplate || showAddNewTemplate ? (
                    <TemplateForm
                      template={selectedTemplate}
                      projectUuid={projectUuid}
                      roleUuid={currentRole}
                      isRefetching={isFetchingTemplate}
                      onRefetchData={refetchData}
                      onCancel={() => {
                        setSelectedTemplate(null);
                        setShowAddNewTemplate(false);
                      }}
                    />
                  ) : (
                    <Stack gap="16px">
                      <Button
                        variant="contained"
                        startIcon={<Add />}
                        sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                        onClick={() => setShowAddNewTemplate(true)}
                      >
                        Добавить новый шаблон
                      </Button>
                      <MUITable
                        headCells={[
                          {
                            id: 'STT',
                            label: 'STT',
                            renderRow: (_row, index) => index + 1,
                          },
                          {
                            id: 'forEvents',
                            label: 'Для события',
                            renderRow: (row) =>
                              row.forEvents
                                ?.map((item) => FOR_EVENTS_OPTIONS.find((option) => option.value === item)?.label ?? '')
                                .join(', '),
                          },
                          {
                            id: 'subject',
                            label: 'тему письма',
                          },

                        ]}
                        rows={templates}
                        onClickRow={(row) => setSelectedTemplate(row)}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          )}
        </CardContent>
      </Card>
    </Card>
  );
}

export default EmailSettings;
