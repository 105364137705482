import React, { useMemo, useState } from 'react';
import Document from 'src/components/document';
import Pagination from 'src/components/pagination';
import { DOCUMENT_TYPES } from 'src/constants';
import { getFullName } from 'src/utils/common';
import { PRIORITY_DOCUMENT_TYPE } from 'src/utils/constant';

function ProfileDocuments({ profileInfo, documents: documentsProp, projectUuid }) {
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 4;

  const documents = useMemo(() => {
    return (
      documentsProp?.filter((item) =>
        item.type === DOCUMENT_TYPES.CONFIRMATION_LETTER ? item.projectUuid === projectUuid : true
      ) ?? []
    );
  }, [documentsProp, projectUuid]);

  if (documents.length === 0) {
    return <div>Нет документов</div>;
  }
  return (
    <>
      {documents
        .slice((currentPage - 1) * LIMIT, currentPage * LIMIT)
        .sort((a, b) => (PRIORITY_DOCUMENT_TYPE[b.type] || 0) - (PRIORITY_DOCUMENT_TYPE[a.type] || 0))
        .map((item) => (
          <Document document={item} key={item.uuid} userName={getFullName(profileInfo)} />
        ))}
      <Pagination
        totalCount={documents.length}
        currentPage={currentPage}
        onChangePage={(page) => setCurrentPage(page)}
        amountPerPage={LIMIT}
      />
    </>
  );
}

export default ProfileDocuments;
