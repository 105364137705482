import { Check, Clear, Download } from '@mui/icons-material';
import { Avatar, Button, Fab, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonLoading from 'src/components/button-loading';
import { MUITable } from 'src/components/mui-table';
import UserStatus from 'src/components/status/UserStatus';
import { routes } from 'src/constants';
import { useAbility } from 'src/hooks/useAbility';
import { projectAPIs } from 'src/services';
import { updateUserByProjectUuid } from 'src/store/actions/application';
import { ACCESSES } from 'src/utils/ability';
import { isSafari, preventSpreadEvent } from 'src/utils/common';
import { MESSAGES } from 'src/utils/localization';
import { createErrorNotification } from 'src/utils/notifications';

function UserList({ loading, onReloadTable }) {
  const navigate = useNavigate();
  const ability = useAbility();

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);
  const currentProject = useSelector((state) => state.application.currentProject);
  const roles = useSelector((state) => state.application.roles);
  const lang = useSelector((state) => state.application.lang);

  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);

  const tab = 'total';
  const projectUuid = currentProject?.uuid;
  const canAccrediteUser = ability.can(ACCESSES.ACCREDITE_USER);
  const canPrintBadge = ability.can(ACCESSES.PRINT_BADGE);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdateUser = async ({ userUuid, isAccredited }) => {
    if (userUuid) {
      await updateUserByProjectUuid(userUuid, { isAccredited });
      onReloadTable();
    }
  };

  // const handleDownloadTicket = async (userUuid) => {
  //   if (!userUuid) return;
  //   if (!currentProject) {
  //     return createErrorNotification('Требуется текущий проект');
  //   }
  //   const url = `/my-ticket/${userUuid}/${currentProject?.uuid}`;
  //   if (isSafari) {
  //     navigate(url);
  //   } else {
  //     window.open(url, '_blank');
  //   }
  // };

  const headCells = [
    {
      id: 'avatar',
      numeric: false,
      disablePadding: true,
      label: '',
      renderRow: (row) => {
        return (
          <Stack padding="10px">
            <Avatar alt="" sx={{ fontSize: '12px' }}>
              {(row.lastName?.[0] || '') + (row.firstName?.[0] || '')}
            </Avatar>
          </Stack>
        );
      },
    },
    {
      id: 'last_name',
      numeric: false,
      disablePadding: false,
      label: 'фамилия',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.lastName || ''}</Typography>,
    },
    {
      id: 'first_name',
      numeric: false,
      disablePadding: false,
      label: 'имя',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.firstName || ''}</Typography>,
    },
    {
      id: 'middle_name',
      numeric: false,
      disablePadding: false,
      label: 'отчество',
      isSortable: true,
      renderRow: (row) => <Typography variant="body2">{row.middleName || ''}</Typography>,
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'электронная почта',
    },
    {
      id: 'telephone',
      numeric: false,
      disablePadding: false,
      label: 'номер телефона',
      renderRow: (row) => <Typography variant="body2">{row.phone}</Typography>,
    },
    {
      id: 'anketaCompanyName',
      numeric: false,
      disablePadding: false,
      label: 'Название компании',
      isSortable: true,
      // renderRow: (row) => <Typography variant="body2">{row.anketaCompanyName}</Typography>,
    },
    // {
    //   id: 'accreditedBy',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Кто создал анкета',
    //   renderRow: (row) => {
    //     const accreditedBy = row.accreditedBy;
    //     return <Typography variant="body2">{accreditedBy ? 'Owner' : 'самостоятельно'}</Typography>;
    //   },
    // },
  ];

  // const handleBulkAction = async (action, selectedRows) => {
  //   if (isSubmitting) return;
  //   try {
  //     setIsSubmitting(true);
  //     const isBlobResponse = action === 'download_ticket';
  //     const response = await projectAPIs.massiveActions(
  //       projectUuid,
  //       { userUuids: selectedRows, action },
  //       isBlobResponse
  //     );

  //     if (isBlobResponse) {
  //       const url = window.URL.createObjectURL(response);
  //       window.open(url, '_blank');
  //     } else {
  //       onReloadTable();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     createErrorNotification(MESSAGES[lang].ERROR);
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  return (
    <Stack sx={{ width: '100%' }}>
      <MUITable
        id="project-registers-table"
        loading={loading || isSubmitting}
        headCells={headCells}
        rows={list}
        rowsNum={counts[tab]}
        // selectable={canAccrediteUser || canPrintBadge}
        // isDisableSelect={(row) => {
        //   const isAccredited = row.projectSettings?.[currentProject?.uuid]?.isAccredited;
        //   const readyToAccreditate = row.projectSettings?.[currentProject?.uuid]?.readyToAccreditate;
        //   return !isAccredited && !readyToAccreditate;
        // }}
        // renderBulkActions={(selectedRows) => (
        //   <>
        //     {canAccrediteUser && (
        //       <>
        //         <ButtonLoading
        //           isLoading={isSubmitting}
        //           disabled={isSubmitting}
        //           onClick={() => handleBulkAction('approve_accreditation', selectedRows)}
        //         >
        //           Подвердить
        //         </ButtonLoading>
        //         <ButtonLoading
        //           isLoading={isSubmitting}
        //           disabled={isSubmitting}
        //           onClick={() => handleBulkAction('decline_accreditation', selectedRows)}
        //         >
        //           отказать
        //         </ButtonLoading>
        //       </>
        //     )}
        //     {canPrintBadge && (
        //       <ButtonLoading
        //         isLoading={isSubmitting}
        //         disabled={isSubmitting}
        //         onClick={() => handleBulkAction('download_ticket', selectedRows)}
        //       >
        //         скачать билет
        //       </ButtonLoading>
        //     )}
        //   </>
        // )}
        onClickRow={
          ability.can(ACCESSES.SEE_PROFILE)
            ? (row) => navigate(routes.userDetail.path.replace(':id', row.uuid))
            : undefined
        }
      />
    </Stack>
  );
}

export default UserList;
