import { Avatar, Button, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/components/alert';
import ProfileEditModal from 'src/components/profile-edit-modal';
import AvatarUpload from 'src/components/upload/AvatarUpload';
import { useAbility } from 'src/hooks/useAbility';
import { userAPIs } from 'src/services';
import { ACCESSES } from 'src/utils/ability';
import { getFullName, isSafari } from 'src/utils/common';
import { DOCUMENT_TYPES, EVENTS, LOCALIZATION } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification } from 'src/utils/notifications';
import { HeaderSection } from '.';
import { isBezviz } from '../../constants';
import { deleteUser } from '../../store/actions/application';
import Profile from './Profile';
import ProfileSettings from './ProfileSettings';

export const STATUS_ACCREDIATION = {
  null: {
    label: {
      [LOCALIZATION.ru_RU]: 'обработка',
      [LOCALIZATION.en_US]: 'in process',
    },
    color: 'warning',
  },
  true: {
    label: {
      [LOCALIZATION.ru_RU]: 'одобрено',
      [LOCALIZATION.en_US]: 'approved',
    },
    color: 'success',
  },
  false: {
    label: {
      [LOCALIZATION.ru_RU]: 'не одобрено',
      [LOCALIZATION.en_US]: 'declined',
    },
    color: 'error',
  },
};

function ProfileSection({ userUuid, isLoading, members, membersVizits, fetchMembers, avatarFile, setAvatarFile }) {
  const navigate = useNavigate();
  const ability = useAbility();

  const user = useSelector((state) => state.auth.user);
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const currentProject = useSelector((state) => state.application.currentProject);

  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const handleDeleteUser = () => {
    deleteUser(deleteUserId).then((err) => {
      if (!err) {
        navigate(-1);
      }
    });
  };

  // const handleDownloadTicket = async () => {
  //   if (!userUuid) return;
  //   if (!currentProject) {
  //     return createErrorNotification('Требуется текущий проект');
  //   }
  //   const url = `/my-ticket/${userUuid}/${currentProject?.uuid}`;
  //   if (isSafari) {
  //     navigate(url);
  //   } else {
  //     window.open(url, '_blank');
  //   }
  // };

  const canEditUser = ability.can(ACCESSES.EDIT_USER);

  const uploadAvatar = async (avatarFile, user) => {
    try {
      const formData = new FormData();
      formData.append('fileDocument', avatarFile, `${user?.uuid}-avatar.png`);
      formData.append('type', DOCUMENT_TYPES.AVATAR);
      await userAPIs.uploadAvatarForMemberVizit(user?.uuid, formData);
      setAvatarFile(avatarFile);
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const avatarURL = useMemo(() => {
    if (avatarFile) {
      return URL.createObjectURL(avatarFile);
    }
    return null;
  }, [avatarFile]);

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap">
                {profileInfo && (
                  <Stack direction="row" gap="8px">
                    {canEditUser && (
                      <Button variant="outlined" color="secondary" onClick={() => setShowEditModal(true)}>
                        Редактировать
                        <i className="bi bi-pencil-square ms-2" />
                      </Button>
                    )}
                    {ability.can(ACCESSES.BLOCK_USER) && (
                      <Button variant="outlined" color="error" onClick={() => setDeleteUserId(profileInfo.uuid)}>
                        Удалить
                        <i className="bi bi-trash3 ms-2 text-danger" />
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>

              <HeaderSection>Личные данные</HeaderSection>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={currentProject ? '28px' : '28px'}
              >
                <Stack direction="row" alignItems="center" gap="12px" flexWrap="wrap">
                  <Stack gap="8px" alignItems="center">
                    <Avatar
                      src={avatarURL}
                      sx={{ width: '86px', height: '86px', cursor: avatarURL ? 'pointer' : 'default' }}
                      onClick={() => {
                        if (avatarURL) {
                          eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, {
                            images: [{ src: avatarURL }],
                          });
                        }
                      }}
                    />
                    {canEditUser && (
                      <AvatarUpload
                        buttonSize="small"
                        uploadAvatar={async (file) => {
                          if (file && profileInfo) {
                            await uploadAvatar(file, profileInfo);
                          }
                        }}
                      />
                    )}
                  </Stack>
                  {profileInfo && (
                    <Stack marginBottom="32px">
                      <Typography variant="body1">{getFullName(profileInfo)}</Typography>
                      <Typography variant="caption">{profileInfo.email}</Typography>
                    </Stack>
                  )}
                </Stack>
                {/* <Button startIcon={<FileDownloadIcon />} variant="contained" onClick={handleDownloadTicket}>
                  скачать билет
                </Button> */}
              </Stack>

              {isLoading ? (
                <CircularProgress />
              ) : profileInfo ? (
                <Profile userUuid={userUuid} isBezviz={isBezviz(user)} members={members} />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ paddingLeft: '40px', paddingRight: '40px', '& > div': { marginTop: '40px' } }}>
              {isLoading ? (
                <CircularProgress />
              ) : profileInfo ? (
                <ProfileSettings
                  userUuid={userUuid}
                  isBezviz={isBezviz(user)}
                  members={members}
                  membersVizits={membersVizits}
                  fetchMembers={fetchMembers}
                />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {showEditModal && <ProfileEditModal onClose={() => setShowEditModal(false)} />}
      {deleteUserId && (
        <Alert
          onClose={() => setDeleteUserId(false)}
          onSubmit={handleDeleteUser}
          title="Вы точно хотите удалить этого пользователя?"
          submitLabel="Удалить"
        />
      )}
    </>
  );
}

export default ProfileSection;
