import { Add, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'src/components/loader';
import { getComparator } from 'src/components/mui-table';
import TableSortIcon from 'src/components/table-sort-icon';
import TextFieldSearch from 'src/components/textfield-search';
import { ORDER_DIRECTION } from 'src/utils/constant';
import { request } from 'src/utils/request';
import { PrimaryMainTypography } from '../AnketaSettings';
import TemplateForm from './TemplateForm';

let typing;
function TemplatesSection({ ableAddField, disableAddMore, notFoundAnketa, onAddAlreadyField }) {
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState('');
  const [openForm, setOpenForm] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const orderBy = 'name';
  const [debouncedSearchText, setDebouncedSearchText] = useState('');

  const fetchFieldTemplates = async ({ queryKey }) => {
    const [, searchText] = queryKey;
    const resTemplates = await request(
      '/internal/anketas/field_templates' + (searchText ? `?query=${searchText}` : '')
    );
    return resTemplates.message || [];
  };

  const {
    data: templates,
    isFetching,
    refetch,
  } = useQuery(['fetchFieldTemplates', debouncedSearchText], fetchFieldTemplates);

  const handleSearch = (value) => setSearchText(value);

  const handleRequestSort = () => {
    const isAsc = order === ORDER_DIRECTION.ASC;
    const isDesc = order === ORDER_DIRECTION.DESC;
    setOrder(isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC);
  };

  const handleCreateTemplate = () => {
    setOpenForm(true);
  };

  const handleEditTemplate = (template) => {
    setOpenForm(true);
    setEditingTemplate(template);
  };

  useEffect(() => {
    clearTimeout(typing);
    typing = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300);
    return () => {
      clearTimeout(typing);
    };
  }, [searchText]);

  return (
    <Stack gap="18px">
      {openForm && (
        <TemplateForm
          onClose={() => {
            setOpenForm(false);
            setEditingTemplate(null);
          }}
          field={editingTemplate}
          onRefreshTemplates={refetch}
        />
      )}
      <Stack direction="row" justifyContent="space-between" gap="16px">
        <PrimaryMainTypography variant="caption">готовые поля</PrimaryMainTypography>
        {templates && templates.length > 0 && <Typography variant="caption">Всего {templates.length} полей</Typography>}
      </Stack>
      <Stack gap="10px">
        <Stack direction="row" alignItems="center" gap="16px">
          <TableSortIcon order={order} orderBy={orderBy} onClick={handleRequestSort} />
          <TextFieldSearch onSearch={handleSearch} />
        </Stack>

        <Button
          variant="outlined"
          startIcon={<Add />}
          disabled={disableAddMore && notFoundAnketa}
          onClick={handleCreateTemplate}
        >
          готовое поле
        </Button>

        {isFetching ? (
          <Loader size="24px" />
        ) : (
          <>
            {templates
              .slice()
              .sort(getComparator(order, orderBy))
              .map((template, i) => (
                <Box key={`template-${i}`}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                      background: '#EDEDF3',
                      borderRadius: '16px',
                      padding: '4px 20px',
                      alignItems: 'center',
                      minHeight: '44px',
                      '&:hover': {
                        paddingRight: ableAddField ? '10px' : '20px',
                        '.add-button': {
                          display: ableAddField ? 'inline-flex' : 'none',
                        },
                        '.edit-button': {
                          display: 'inline-flex',
                        },
                      },
                    }}
                  >
                    <Tooltip title={template.name} placement="top">
                      <Typography
                        variant="body2"
                        sx={{
                          // whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          //textOverflow: 'ellipsis',
                          color: (theme) => theme.palette.text.primary,
                        }}
                      >
                        {template.name}
                      </Typography>
                    </Tooltip>

                    <Stack direction="row" gap="4px">
                      <IconButton
                        className="edit-button"
                        sx={{ display: 'none' }}
                        onClick={() => handleEditTemplate(template)}
                      >
                        <Edit />
                      </IconButton>
                      {ableAddField && (
                        <IconButton
                          className="add-button"
                          sx={{ display: 'none' }}
                          onClick={() => onAddAlreadyField(template)}
                        >
                          <Add />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              ))}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default TemplatesSection;
