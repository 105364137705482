import { Clear } from '@mui/icons-material';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { isArray } from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { useQuery } from 'react-query';
import { generalAPIs } from 'src/services';

function TagFilter() {
  const fetchTags = async () => {
    const response = await generalAPIs.getTags();
    const raw = response.message;
    return raw && isArray(raw) ? raw : [];
  };

  const { data: tags = [] } = useQuery('fetchTags', fetchTags, { staleTime: 0 });

  return (
    <Field name="tags" component="select">
      {({ input, meta }) => (
        <FormControl>
          <InputLabel id="tag-label" shrink={true}>
            Тег
          </InputLabel>
          <Select
            notched
            label="Тег"
            labelId="tag-label"
            multiple={true}
            value={isArray(input.value) ? input.value : []}
            onChange={input.onChange}
            sx={{
              '& .MuiSelect-select': {
                paddingRight: '4px !important',
              },
            }}
            endAdornment={
              isArray(input.value) && input.value?.length > 0 ? (
                <IconButton onClick={() => input.onChange('')} sx={{ marginRight: '14px' }}>
                  <Clear />
                </IconButton>
              ) : (
                <></>
              )
            }
          >
            {tags.length === 0 && <Typography variant="body2">Нет вариантов</Typography>}
            {tags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}

export default TagFilter;
