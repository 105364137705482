import { Box, Card, CardContent, Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Select from 'src/@core/components/mui/select';
import Loader from 'src/components/loader';
import { request } from 'src/utils/request';
import TicketForm from './TicketForm';
import TicketCreate from './TicketCreate';
import { createNotification } from 'src/utils/notifications';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

const PrimaryMainTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
}));

function TicketSettings({ projectUuid, isAdditional = false }) {
  const currentProject = useSelector((state) => state.application.currentProject);
  const projects = useSelector((state) => state.application.projects);
  const location = useLocation();

  const [roles, setRoles] = useState([]);
  const [currentRole, setCurrentRole] = useState();
  const [templates, setTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [disableAddMore, setDisableAddMore] = useState(false);
  const [showAddNewField, setShowAddNewField] = useState(false);

  const fetchTicketData = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid, isAdditional] = queryKey;
    const res = await request(
      `/internal/tickets?projectUuid=${projectUuid}&roleUuid=${roleUuid}&isAdditional=${isAdditional}`
    );
    return res?.message;
  };

  const fetchAnketaFields = async ({ queryKey }) => {
    const [_, projectUuid, roleUuid] = queryKey;
    const res = await request(`/internal/anketas?projectUuid=${projectUuid}&roleUuid=${roleUuid}`);
    return res?.message?.fields;
  };

  const {
    isLoading,
    error,
    data,
    refetch: refetchTicketData,
    isFetching: isFetchingTicketData,
  } = useQuery(['fetchTicketData', projectUuid, currentRole, isAdditional], fetchTicketData, {
    enabled: !!projectUuid && !!currentRole,
  });

  const { data: anketaFields } = useQuery(['fetchAnketaFields', projectUuid, currentRole], fetchAnketaFields, {
    enabled: !!projectUuid && !!currentRole,
  });

  const initTicket = async () => {
    try {
      const [resRoles] = await Promise.all([request(`/roles?projectUuid=${projectUuid}`)]);
      setRoles(resRoles.message || []);
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    } finally {
      setIsLoadingTemplates(false);
    }
  };

  useEffect(() => {
    initTicket();
  }, []);

  useEffect(() => {
    const objSearch = qs.parse(location.search);
    if (location?.state?.defaultRoleUuid) {
      setCurrentRole(location?.state?.defaultRoleUuid);
    } else if (objSearch && objSearch.currentRole) {
      setCurrentRole(objSearch.currentRole);
    }
  }, [location]);

  const statusCode = error ? error.code : 200;
  const notFoundTicket = statusCode === 404;

  // const handlePatchAnketa = async (body) => {
  //   try {
  //     await request(`/internal/anketas/${data.uuid}`, {
  //       method: 'PATCH',
  //       body,
  //     });
  //     refetchTicketData();
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.message?.error);
  //   }
  // };

  // const handleMoveFiled = (field, delta) => {
  //   try {
  //     const currentPosition = field.position;
  //     const nextPosition = currentPosition + delta;

  //     handlePatchAnketa({
  //       [anketaFields[nextPosition - 1].uuid]: {
  //         position: currentPosition,
  //       },
  //       [field.uuid]: { position: nextPosition },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     toast.error(error.message?.error);
  //   }
  // };

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" gap="16px">
          <Typography variant="h6" sx={{ whiteSpace: 'nowrap', marginRight: '20px' }}>
            Для роли:
          </Typography>
          <Select
            fullWidth={true}
            label="Выберите роль"
            options={roles}
            value={currentRole}
            getOptionLabel={(item) => item.name?.ru_RU}
            getOptionValue={(item) => item.id}
            onChange={(value) => setCurrentRole(value)}
          />
        </Stack>
      </CardContent>

      <Card>
        <CardContent>
          <Box paddingY="20px">
            <Stack gap="16px">
              {!currentRole && (
                <p style={{ textAlign: 'center' }}>
                  {isAdditional
                    ? 'Выберите роль для создания шаблон бейдж'
                    : 'Выберите роль для создания шаблон билета'}
                </p>
              )}
              {currentRole &&
                (isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {/* {notFoundTicket && !showAddNewField ? (
                      <TicketCreate
                        projectUuid={projectUuid}
                        roleUuid={currentRole}
                        setDisableAddMore={setDisableAddMore}
                        setShowAddNewField={setShowAddNewField}
                        refetchTicketData={refetchTicketData}
                      />
                    ) : ( */}
                    <TicketForm
                      isAdditional={isAdditional}
                      isRefetching={isFetchingTicketData}
                      projectUuid={projectUuid}
                      roleUuid={currentRole}
                      ticket={data}
                      anketaFields={anketaFields}
                      setShowAddNewField={setShowAddNewField}
                      refetchTicketData={refetchTicketData}
                    />
                    {/* )} */}
                  </>
                ))}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Card>
  );
}

export default TicketSettings;
