import { isEqual } from 'lodash';
import * as Yup from 'yup';
import { REGEX_E164_PHONE, REGEX_FULL_NAME } from '../constants';
import { LOCALIZATION } from './constant';

export const filterUnChangedFields = (baseObject = {}, newObject = {}) => {
  let clonedNewObject = { ...newObject };
  Object.keys(newObject).forEach((key) => {
    if (isEqual(newObject[key], baseObject[key])) {
      delete clonedNewObject[key];
    }
  });
  return clonedNewObject;
};

const VALIDATOR_MESSAGE = {
  [LOCALIZATION.ru_RU]: {
    FULL_NAME_IS_REQUIRED: 'Укажите полное имя',
    FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS: 'Полное имя должно состоять как минимум из двух слов',
    EMAIL_INVALID: 'Электронная почта недействительна',
    PLEASE_FILL_OUT_THIS_FIELD: 'Пожалуйста, заполните это поле',
    PASSWORD_MIN_SIX_CHARACTERS: 'Пароль должен быть не менее 6 символов',
    PASSWORD_DOES_NOT_MATCH: 'Пароль не подходит',
    PHONE_INVALID: 'Телефон недействителен',
    END_DATE_BEFORE_START_DATE: "End date can't be before Start date",
    REQUIRE_LENGTH_NUMBER: 'Требуется {number} числа',
    REQUIRE_LENGTH_CHARACTER: 'Требуется {number} персонажи',
    MAX_VALUE_MUST_LARGER_THAN_MIN_VALUE: 'Максимальное значение должно быть больше минимального',
    VALUE_IS_POSITIVE: 'Значение должно быть положительным числом',
    VALUE_IN_RANGE: 'Значение должно быть в диапазоне от {min} - {max}',
    MAX_CHARACTER: 'Максимум {max} символов',
  },
  [LOCALIZATION.en_US]: {
    FULL_NAME_IS_REQUIRED: 'Full name is required',
    FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS: 'Full name must have at least two words',
    EMAIL_INVALID: 'Email is invalid',
    PLEASE_FILL_OUT_THIS_FIELD: 'Please fill out this field',
    PASSWORD_MIN_SIX_CHARACTERS: 'Password must be at least 6 characters',
    PASSWORD_DOES_NOT_MATCH: 'Password does not match',
    PHONE_INVALID: 'Phone is invalid',
    END_DATE_BEFORE_START_DATE: "End date can't be before Start date",
    REQUIRE_LENGTH_NUMBER: 'Required {number} numbers',
    REQUIRE_LENGTH_CHARACTER: 'Required {number} characters',
    MAX_VALUE_MUST_LARGER_THAN_MIN_VALUE: 'The maximum value must be larger than the minimum value',
    VALUE_IS_POSITIVE: 'The value must be a positive number',
    VALUE_IN_RANGE: 'The value must be in range from {min} - {max}',
    MAX_CHARACTER: 'Maximum {max} characters',
  },
};

const lang = LOCALIZATION.ru_RU;

export const validatorMessage = VALIDATOR_MESSAGE[lang];

export const companyValidationSchema = Yup.object().shape({
  name: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  fullName: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_FULL_NAME, validatorMessage.FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
  extLegalAddress: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  countryOfRegistration: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  phone: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_E164_PHONE, validatorMessage.PHONE_INVALID),
  webAddress: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  contactPerson: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD).email(validatorMessage.EMAIL_INVALID),
  companyType: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  kpp: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .length(9, validatorMessage.REQUIRE_LENGTH_NUMBER.replace('{number}', 9)),
  inn: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .length(10, validatorMessage.REQUIRE_LENGTH_NUMBER.replace('{number}', 10)),
});

export const vizitsValidationSchema = Yup.object().shape({
  name: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  country: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  region: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  address: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  telephone: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_E164_PHONE, validatorMessage.PHONE_INVALID),
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD).email(validatorMessage.EMAIL_INVALID),
  web: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  scope: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  services: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  // description: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export const membersVizitsValidationSchema = Yup.object().shape({
  telephone: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_E164_PHONE, validatorMessage.PHONE_INVALID),
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD).email(validatorMessage.EMAIL_INVALID),
  fullName: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_FULL_NAME, validatorMessage.FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
});

export const userValidationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_FULL_NAME, validatorMessage.FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
  city: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  // gender: Yup.string().required('Gender is required'),
  companyName: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  phone: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_E164_PHONE, validatorMessage.PHONE_INVALID)
    .max(14, validatorMessage.PHONE_INVALID),
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD).email(validatorMessage.EMAIL_INVALID),
  legalFormCountry: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  legalFormCity: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  legalFormTel: Yup.string().max(14, validatorMessage.PHONE_INVALID),
  extentCommercialDecisions: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  scopeCompany: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  typeActivityCompany: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  interestedSections: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  purposeVisiting: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  // amountPotentialPurchases: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  howToKnow: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  // blocksCongressProgram: Yup.array().min(1, validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export const userExpertValidationSchema = Yup.object().shape({
  lastName: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  firstName: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  city: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  companyName: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  email: Yup.string().email(validatorMessage.EMAIL_INVALID),
  phone: Yup.string().max(14, validatorMessage.PHONE_INVALID),
  legalFormCountry: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  legalFormCity: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  legalFormTel: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .max(14, validatorMessage.PHONE_INVALID),
});

export const staffLoginValidationSchema = Yup.object().shape({
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD).email(validatorMessage.EMAIL_INVALID),
  password: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  projectUuid: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export const supportedUserCreateValidationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .matches(REGEX_FULL_NAME, validatorMessage.FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
  email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
  password: Yup.string()
    .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
    .min(8, validatorMessage.REQUIRE_LENGTH_CHARACTER.replace('{number}', 8)),
  expiredDate: Yup.date().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

export const supportedUserEditValidationSchema = (isChangePassword) =>
  isChangePassword
    ? Yup.object().shape({
        password: Yup.string()
          .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
          .min(8, validatorMessage.REQUIRE_LENGTH_CHARACTER.replace('{number}', 8)),
      })
    : Yup.object().shape({
        fullName: Yup.string()
          .required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
          .matches(REGEX_FULL_NAME, validatorMessage.FULL_NAME_MUST_HAVE_AT_LEAST_TWO_WORDS),
        email: Yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
        expiredDate: Yup.date().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
      });
