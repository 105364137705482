import { Button, Grid, Stack } from '@mui/material';
import qs from 'query-string';
import { Form } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import Input from 'src/components/input';
import Select from 'src/components/select';
import useResponsive from 'src/hooks/useResponsive';
import { LOCALIZATION, STATUS_PAYMENT } from 'src/utils/constant';
import classes from './filters.module.sass';
import Checkbox from 'src/components/checkbox';
const CHECKBOXES = {
  paid: 'Оплачено',
  notPaid: 'Не оплачено',
  orderByCard: 'Оплата по карте',
  orderByCompany: 'Оплата по счету',
};
export default function OrderFilters({ onSubmit }) {
  const isDesktop = useResponsive();
  const location = useLocation();

  const PAYMENT_METHOD_OPTIONS = [
    {
      id: 'order_by_card',
      label: 'Оплата банковской картой',
    },
    {
      id: 'order_by_company',
      label: 'Оплата по счёту',
    },
  ];

  const STATUS_OPTION = Object.keys(STATUS_PAYMENT).map((key) => ({
    id: key,
    label: STATUS_PAYMENT[key].label[LOCALIZATION.ru_RU],
  }));

  const query = qs.parse(location.search, { parseBooleans: true });
  const initialValues = {
    ...query,
  };
  const handleSubmit = (filters) => {
    let baseFilter = { ...filters };
    Object.keys(CHECKBOXES).forEach((key) => {
      if (!baseFilter[key]) {
        delete baseFilter[key];
      }
    });
    return onSubmit({
      ...baseFilter,
    });
  };
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className={classes.filters}>
          <Stack gap={isDesktop ? '36px' : '24px'}>
            <Grid container spacing={12}>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="companyName" label="Название компании" />
                  <Input name="packageName" label="Имя пакета" />
                  <Input name="thezisId" label="Thezis ID" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="20px">
                  <Input name="lastName" label="Фамилия" />
                  <Input name="firstName" label="Имя" />
                  <Input name="middleName" label="Отчество" />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Stack gap="8px">
                  {Object.keys(CHECKBOXES).map((key) => (
                    <Checkbox key={key} name={key} label={CHECKBOXES[key]} />
                  ))}
                </Stack>
              </Grid>
            </Grid>

            <Grid container rowSpacing={0} columnSpacing={12}>
              <Grid item xs={12} md={12} lg={4}></Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => {
                    form.restart();
                    onSubmit({});
                  }}
                >
                  Очистить фильтры
                </Button>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button type="submit" variant="contained" className={classes.button}>
                  Искать
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </form>
      )}
    </Form>
  );
}
