import { Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useLanguage } from 'src/hooks';
import useResponsive from 'src/hooks/useResponsive';
import AnketaFields from './AnketaFields';
import { useForm } from 'react-hook-form';

function PreviewAnketaForm({ projectUuid, data }) {
  const isDesktop = useResponsive();
  const { localizeMessage } = useLanguage();

  const { control, errors, register } = useForm();

  const fields = useMemo(() => data?.fields ?? [], [data]);
  const user = {};
  const userUuid = '';

  const splitArrayInHalf = (array) => {
    const half = Math.ceil(array.length / 2);
    return [array.slice(0, half), array.slice(half)];
  };

  const [leftArray, rightArray] = useMemo(() => (fields?.length > 0 ? splitArrayInHalf(fields) : [[], []]), [fields]);

  return (
    <Stack gap="32px">
      {!fields?.length && <Typography variant="body1">{localizeMessage.THERE_ARE_NO_FIELDS_TO_EDIT}</Typography>}

      <Stack gap={2}>
        {isDesktop ? (
          <>
            {leftArray.map((field, fieldIndex) => (
              <Grid key={`field-${fieldIndex}`} container columnSpacing={8} rowSpacing={2} alignItems="flex-end">
                <Grid item xs={12} md={6}>
                  <AnketaFields
                    key={field.uuid}
                    field={field}
                    user={user}
                    register={register}
                    control={control}
                    errors={errors}
                    userUuid={userUuid}
                    projectUuid={projectUuid}
                    // disabled={!canEditUser || isLocked || isLockedField(field)}
                    // countries={countries}
                    // autoFillTemplate={autoFillTemplate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {rightArray[fieldIndex] && (
                    <AnketaFields
                      key={rightArray[fieldIndex].uuid}
                      field={rightArray[fieldIndex]}
                      user={user}
                      register={register}
                      control={control}
                      errors={errors}
                      userUuid={userUuid}
                      projectUuid={projectUuid}
                      //   disabled={!canEditUser || isLocked || isLockedField(rightArray[fieldIndex])}
                      //   countries={countries}
                      //   autoFillTemplate={autoFillTemplate}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {fields.map((field) => (
              <AnketaFields
                key={field.uuid}
                field={field}
                user={user}
                register={register}
                control={control}
                errors={errors}
                userUuid={userUuid}
                projectUuid={projectUuid}
                // disabled={!canEditUser || isLocked || isLockedField(field)}
                // countries={countries}
                // autoFillTemplate={autoFillTemplate}
              />
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default PreviewAnketaForm;
