import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createRootReducer from './reducers';

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const enhancedCompose =
  process.env.REACT_APP_SERVER_URL === 'https://dev.admin.pro-firpo.ru' ? composeWithDevTools : compose;

const middleware = [routerMiddleware(history)];
const enhancer = enhancedCompose(applyMiddleware(...middleware));

const persistedState = localStorage.getItem('visaState') ? JSON.parse(localStorage.getItem('visaState')) : {};

export const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(() => {
  localStorage.setItem('visaState', JSON.stringify(store.getState()));
});

export const getCurrentState = () => store.getState();
export const dispatch = (...args) => store.dispatch(...args);
