import { Article, Delete } from '@mui/icons-material';
import { Box, Divider, IconButton, Link, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import Loader from 'src/components/loader';
import { documentAPIs } from 'src/services';
import { EVENTS, IMAGE_FILE_TYPES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';

const imageTypes = [
  { type: 'image/jpeg', ext: 'jpeg' },
  { type: 'image/png', ext: 'png' },
];
const fileTypes = [{ type: 'application/pdf', ext: 'pdf' }];

const getExt = (type) => {
  const img = imageTypes.find((i) => i.type === type);
  const file = fileTypes.find((i) => i.type === type);
  return img?.ext || file?.ext;
};

function DocumentFile({ document, onDeleteDocument, sx = {} }) {
  const [file, setFile] = useState(null);

  const fetchFile = async ({ queryKey }) => {
    const [, document] = queryKey;
    const response = await documentAPIs.getDocumentFile(document.uuid);
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(response);
    setFile({
      url: imageUrl,
      type: response.type,
      blob: response,
    });
    return imageUrl;
  };

  const { isLoading, isFetching } = useQuery(['fetchFile', document], fetchFile, { enabled: !!document, staleTime: 0 });

  const handleDeleteFile = () => {
    if (!document) return;
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => onDeleteDocument(document),
    });
  };

  const ext = getExt(file?.type);
  const properties = document?.properties || {};

  return (
    <>
      <Stack gap="16px" sx={{ position: 'relative', ...sx }}>
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: '4px',
            minWidth: '100px',
            minHeight: '100px',
            width: '100px',
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            cursor: 'pointer',
            '&:hover': {
              borderStyle: 'dashed',
            },
          }}
        >
          {isLoading || isFetching ? (
            <Loader />
          ) : (
            <Link href={file?.url} target="_blank" sx={{ width: '100%', height: '100%' }}>
              {IMAGE_FILE_TYPES.includes(ext?.toUpperCase()) ? (
                <img src={file?.url} width="100%" height="100%" style={{ objectFit: 'contain' }} />
              ) : (
                <Stack sx={{ alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                  <Article sx={{ fontSize: '64px' }} />
                </Stack>
              )}
            </Link>
          )}
        </Box>
        <Stack gap="8px">
          <Stack>
            <Typography variant="body1" fontWeight={500} sx={{ overflowWrap: 'anywhere' }}>
              {properties.fileName}
            </Typography>
            <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
              {properties.description}
            </Typography>
          </Stack>
          {onDeleteDocument && (
            <IconButton
              sx={{
                border: '1px solid',
                borderRadius: '4px',
                width: 'fit-content',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              color="error"
              onClick={handleDeleteFile}
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Divider sx={{ width: '100%' }} />
    </>
  );
}

export default DocumentFile;
