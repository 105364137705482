import { Autocomplete, Box, Button, Chip, createFilterOptions, Stack, styled, TextField } from '@mui/material';
import Papa from 'papaparse';
import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import { useLanguage } from 'src/hooks';
import { createErrorNotification } from 'src/utils/notifications';

const filter = createFilterOptions();
const SortableItem = SortableElement(({ value, onDelete }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <Chip
      sx={{ zIndex: 9999 }}
      key={value.inputValue}
      label={value.title}
      onMouseDown={onMouseDown}
      onDelete={() => onDelete(value.inputValue)}
    />
  );
});

const SortableList = SortableContainer(({ items, onDelete }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem key={`item-${value.inputValue}`} index={index} value={value} onDelete={onDelete} />
    ))}
  </div>
));
function AnketaOptionField({ label, options = [], setOptions, disabled, error }) {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setOptions((oldV) => {
      const temp = [...oldV];
      [temp[oldIndex], temp[newIndex]] = [temp[newIndex], temp[oldIndex]];
      return temp;
    });
  };
  const onDelete = (val) => {
    setOptions(options.filter((el) => el.inputValue !== val));
  };
  return (
    <>
      <Autocomplete
        multiple={true}
        value={options || []}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setOptions([...options, { inputValue: newValue, title: `"${newValue}"` }]);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setOptions([...options, { inputValue: newValue.inputValue, title: `"${newValue.inputValue}"` }]);
          } else {
            setOptions(
              newValue.map((value) => (typeof value === 'string' ? { inputValue: value, title: `"${value}"` } : value))
            );
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.inputValue);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `"${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        renderTags={(value) => (
          <SortableList
            items={value}
            axis="xy"
            onSortEnd={onSortEnd}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            onDelete={onDelete}
          />
        )}
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ ...params?.InputLabelProps, shrink: true }}
            InputProps={{
              ...params?.InputProps,
              sx: { minHeight: '128px', alignItems: 'flex-start' },
            }}
            label={label}
            error={!!error}
            helperText={error}
          />
        )}
        disabled={disabled}
      />
      <OptionFileButtons options={options} setOptions={setOptions} />
    </>
  );
}

const OptionFileButtons = ({ options = [], setOptions }) => {
  const { localizeText, localizeMessage } = useLanguage();
  const OptionFileButton = styled(Button)(({ theme }) => ({
    fontSize: '10px',
    textAlign: 'center',
    flexGrow: 1,
    flexBasis: 0,
  }));
  const handleImport = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        Papa.parse(file, {
          complete: (results) => {
            const rows = results.data.map((row) => ({ inputValue: row[0], title: row[0] }));
            setOptions(rows);
          },
          header: false,
        });
      }
    } catch (error) {
      createErrorNotification(localizeMessage.ERROR, 'error');
    }
  };

  console.log('OptionFileButtons options', options);
  const csvData = useMemo(() => {
    return options.map((el) => [el.inputValue]);
  }, [options]);
  return (
    <Box mt={'-12px'} gap={'5px'} flexGrow={1} display="flex" flexDirection="row" justifyContent="space-between">
      <OptionFileButton component="label" disabled={!options.length} size="small" variant="outlined">
        <CSVLink style={{ color: 'inherit' }} data={csvData} filename="options.csv">
          {localizeText.SAVE_CSV_BTN}
        </CSVLink>
      </OptionFileButton>
      <OptionFileButton component="label" size="small" variant="outlined">
        {localizeText.LOAD_CSV_BTN}
        <input hidden type="file" accept=".csv" onChange={handleImport} />
      </OptionFileButton>
    </Box>
  );
};
export default AnketaOptionField;
