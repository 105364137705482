import { Upload } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';

function ImportUserButton({ projectUuid, onReloadTable }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { localizeMessage } = useLanguage();

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      createNotification(localizeMessage.ERROR, 'error');
      return;
    }
    try {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('fileDocument', file);
      // TODO: Update API endpoint
      await request(`/internal/users/` + projectUuid + `/import`, {
        body: formData,
        method: 'POST',
      });
      createNotification(localizeMessage.CREATE_SUCCESSFULLY, 'success');
      onReloadTable();
    } catch (error) {
      console.error(error);
      createNotification(localizeMessage.ERROR, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Button
        variant="outlined"
        startIcon={isSubmitting ? <Loader color="inherit" size="12px" /> : <Upload />}
        disabled={isSubmitting}
        component="label"
      >
        импортировать
        <input
          hidden
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onChange={handleUploadFile}
        />
      </Button>
    </Box>
  );
}

export default ImportUserButton;
