import { Avatar, Button, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from 'src/components/alert';
import ProfileEditModal from 'src/components/profile-edit-modal';
import UserIcon from 'src/layouts/components/UserIcon';
import { getMembersVizitByProject, getUserVizitByProject } from 'src/store/actions/vizit';
import { getFullName, isSafari } from 'src/utils/common';
import { HeaderSection } from '.';
import { isBezviz } from '../../constants';
import { deleteUser, searchUser } from '../../store/actions/application';
import Profile from './Profile';
import ProfileSettings from './ProfileSettings';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { userAPIs } from 'src/services';
import { DOCUMENT_TYPES, LOCALIZATION } from 'src/utils/constant';
import { createErrorNotification } from 'src/utils/notifications';
import UserStatus from 'src/components/status/UserStatus';
import { useAbility } from 'src/hooks/useAbility';
import { ACCESSES } from 'src/utils/ability';

export const STATUS_ACCREDIATION = {
  null: {
    label: {
      [LOCALIZATION.ru_RU]: 'обработка',
      [LOCALIZATION.en_US]: 'in process',
    },
    color: 'warning',
  },
  true: {
    label: {
      [LOCALIZATION.ru_RU]: 'одобрено',
      [LOCALIZATION.en_US]: 'approved',
    },
    color: 'success',
  },
  false: {
    label: {
      [LOCALIZATION.ru_RU]: 'не одобрено',
      [LOCALIZATION.en_US]: 'declined',
    },
    color: 'error',
  },
};

function ProfileSection({ userUuid, isLoading, members, membersVizits, fetchMembers }) {
  const navigate = useNavigate();
  const ability = useAbility();

  const user = useSelector((state) => state.auth.user);
  const profileInfo = useSelector((state) => state.application.profileInfo);
  const currentProject = useSelector((state) => state.application.currentProject);

  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  const handleDeleteUser = () => {
    deleteUser(deleteUserId).then((err) => {
      if (!err) {
        navigate(-1);
      }
    });
  };

  // const handleDownloadTicket = async () => {
  //   if (!userUuid) return;
  //   if (!currentProject) {
  //     return createErrorNotification('Требуется текущий проект');
  //   }
  //   const url = `/my-ticket/${userUuid}/${currentProject?.uuid}`;
  //   if (isSafari) {
  //     navigate(url);
  //   } else {
  //     window.open(url, '_blank');
  //   }
  // };

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <HeaderSection>Личные данные</HeaderSection>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={currentProject ? '28px' : '28px'}
              >
                <Stack direction="row" alignItems="center" gap="12px" flexWrap="wrap">
                  <Avatar sx={{ width: '86px', height: '86px' }} />
                  {profileInfo && (
                    <Stack>
                      <Typography variant="body1">{getFullName(profileInfo)}</Typography>
                      <Typography variant="caption">{profileInfo.email}</Typography>
                    </Stack>
                  )}
                </Stack>
                {/* <Button startIcon={<FileDownloadIcon />} variant="contained" onClick={handleDownloadTicket}>
                  скачать билет
                </Button> */}
              </Stack>

              {isLoading ? (
                <CircularProgress />
              ) : profileInfo ? (
                <Profile userUuid={userUuid} isBezviz={isBezviz(user)} members={members} />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ paddingLeft: '40px', paddingRight: '40px', '& > div': { marginTop: '40px' } }}>
              {isLoading ? (
                <CircularProgress />
              ) : profileInfo ? (
                <ProfileSettings
                  userUuid={userUuid}
                  isBezviz={isBezviz(user)}
                  members={members}
                  membersVizits={membersVizits}
                  fetchMembers={fetchMembers}
                />
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {showEditModal && <ProfileEditModal onClose={() => setShowEditModal(false)} />}
      {deleteUserId && (
        <Alert
          onClose={() => setDeleteUserId(false)}
          onSubmit={handleDeleteUser}
          title="Вы точно хотите удалить этого пользователя?"
          submitLabel="Удалить"
        />
      )}
    </>
  );
}

export default ProfileSection;
