import { FormControlLabel } from '@mui/material';
import { Field } from 'react-final-form';
import classes from './checkbox.module.sass';
import MuiCheckbox from '@mui/material/Checkbox';

function Checkbox({ name, label, textvalue }) {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        // <label className={classes.labelContainer} htmlFor={name}>
        //   <input id={name} type="checkbox" className={classes.input} {...input} />
        //   <div className={classes.fakeInput} tabIndex={-1} />
        //   <span className={classes.label}>{label}</span>
        // </label>

        <FormControlLabel label={label} control={<MuiCheckbox name={name} value={textvalue} {...input} />} />
      )}
    </Field>
  );
}

export default Checkbox;
