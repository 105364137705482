import { Clear } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import ButtonLoading from 'src/components/button-loading';
import { MUITable } from 'src/components/mui-table';
import { generalAPIs } from 'src/services';

function InvitationList({ loading, projectUuid, handleSearch = () => { } }) {
  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);

  const fetchRoles = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const res = await generalAPIs.getRoles(projectUuid);
    return res.message?.reduce((p, c) => ({ ...p, [c.id]: c }), {}) ?? {};
  };

  const { isLoading: isLoadingRoles, data: roleByUuid } = useQuery(['fetchRoles', projectUuid], fetchRoles, {
    enabled: !!projectUuid,
  });

  const { register, handleSubmit, getValues, reset } = useForm({ defaultValues: { query: '' } });

  const headCells = [
    { id: 'ownerFullname', numeric: false, disablePadding: false, label: 'ФИО', width: 300 },
    { id: 'ownerCompany', numeric: false, disablePadding: false, label: 'Название компании', width: 300 },
    {
      id: 'quotaByRoles',
      numeric: false,
      disablePadding: false,
      label: 'Квота по ролям',
      renderRow: (row) => {
        return (
          <ul>
            {row.invitations?.map((invitation) => (
              <li key={invitation.uuid}>
                <Stack>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {roleByUuid?.[invitation.roleUuid]?.name?.ru_RU || roleByUuid?.[invitation.roleUuid]?.name?.en_US}
                  </Typography>
                  <Typography variant="body2">Есть: {invitation.maxCount ?? 0}</Typography>
                  <Typography variant="body2">
                    Осталось: {invitation.maxCount ?? 0 - (invitation.currentCount ?? 0)}
                  </Typography>
                  <Typography variant="body2">Кто создал: {invitation.creatorName}</Typography>
                </Stack>
              </li>
            ))}
          </ul>
        );
      },
    },
  ];

  return (
    <Stack>
      <Stack direction="row" gap="8px" justifyContent="flex-end" padding="20px">
        <form noValidate onSubmit={handleSubmit(handleSearch)}>
          <Stack direction="row" gap="8px 24px">
            <TextField
              name="query"
              size="small"
              placeholder="Введите ..."
              sx={{ minWidth: '240px' }}
              {...register('query')}
              InputProps={{
                endAdornment: (
                  <IconButton
                    sx={{ visibility: getValues('query') ? 'visible' : 'hidden' }}
                    onClick={() => {
                      reset();
                      handleSearch({ query: '' });
                    }}
                  >
                    <Clear />
                  </IconButton>
                ),
              }}
            />
            <ButtonLoading type="submit" variant="contained">
              поиск
            </ButtonLoading>
          </Stack>
        </form>
      </Stack>

      <MUITable
        id="project-invitations-table"
        loading={loading || isLoadingRoles}
        headCells={headCells}
        rows={list}
        rowsNum={counts?.['quote'] ?? 0}
      />
    </Stack>
  );
}

export default InvitationList;
