import { useNavigate } from 'react-router-dom';
import ButtonLoading from 'src/components/button-loading';
import { DOCUMENT_TYPES, routes } from 'src/constants';
import { useDownloadFile, useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { isSafari } from 'src/utils/common';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function DownloadTicketButton({ userUuid, projectUuid, disabled }) {
  const navigate = useNavigate();
  const { localizeText, localizeMessage } = useLanguage();
  const { downloadFile } = useDownloadFile();

  const handleDownloadTicket = async () => {
    if (!userUuid) return;
    if (!projectUuid) {
      return createErrorNotification('Требуется текущий проект');
    }

    try {
      const response = await userAPIs.downloadUserDocument(userUuid, DOCUMENT_TYPES.CONFIRMATION_LETTER, projectUuid);
      downloadFile(response, 'билет');
    } catch (error) {
      createNotification(localizeMessage.ERROR, 'error');
    }

    // const url = routes.myTicket.path.replace(':userUuid', userUuid).replace(':projectUuid', projectUuid);
    // if (isSafari) {
    //   navigate(url);
    // } else {
    //   window.open(url, '_blank');
    // }
  };

  return (
    <ButtonLoading variant="outlined" disabled={disabled} onClick={handleDownloadTicket}>
      {localizeText.DOWNLOAD_TICKET}
    </ButtonLoading>
  );
}

export default DownloadTicketButton;
