import qs from 'query-string';
import * as constants from '../constants/application';
import { dispatch, getCurrentState } from '../index';
import { downloadFile, request } from '../../utils/request';
import { getDateForFile } from '../../utils/dates';
import { createNotification } from '../../utils/notifications';
import {
  accreditationStatuses,
  accreditationWithoutProjectUuidStatuses,
  isBezviz,
  noVisaStatuses,
} from '../../constants';
import { FORM_ERROR } from 'final-form';
import { generalAPIs, projectAPIs, staffAPIs, userAPIs } from '../../services';
import { LOCALIZATION, MEETING_STATUS } from '../../utils/constant';
import _ from 'lodash';

export const changePassword = async ({ password, new_password, new_password_again }) => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  if (new_password !== new_password_again) {
    return { [FORM_ERROR]: 'Пароли не совпадают' };
  }
  try {
    await request('/settings/change_password', {
      method: 'POST',
      body: { password, new_password },
    });
  } catch (error) {
    return { [FORM_ERROR]: 'Неверный пароль' };
  }
};

export const settingMfa = async ({}) => {
  const token = localStorage.getItem('token');
  if (!token) return false;
  try {
    const settingData = await request('/login/mfa/settings', {
      method: 'GET',
    });
    dispatch({ type: constants.APPLICATIONS_SETTINGS_MFA, settingData });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getApplicationList = async ({ page = 1, limit = 20, tab = 'processing', ...other }) => {
  const globalState = getCurrentState();
  const user = globalState.auth.user;
  const currentProject = globalState.application.currentProject;
  const currentCounts = globalState.application.counts;
  const isBezvizUser = isBezviz(user);

  try {
    const status = isBezvizUser ? noVisaStatuses[tab] : accreditationStatuses[tab];
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...status?.filterQuery,
      ...other,
      projectUuid: currentProject?.uuid,
    });
    const data = await request(`/internal/users?${queryParams}`);
    const counts = { ...currentCounts, [status?.name]: data.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getInvitationList = async (params) => {
  const globalState = getCurrentState();
  const currentProject = globalState.application.currentProject;
  const currentCounts = globalState.application.counts;

  try {
    const projectUuid = currentProject?.uuid;
    const data = await request(
      `/internal/projects/${projectUuid}/invitations${
        params ? `?${qs.stringify(params, { skipEmptyString: true, skipNull: true })}` : ''
      }`
    );
    const counts = { ...currentCounts, quote: data.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getAllUsers = async ({ page = 1, limit = 20, ...other }) => {
  const globalState = getCurrentState();
  const currentCounts = globalState.application.counts;

  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...other,
    });
    const data = await request(`/internal/users?${queryParams}`);
    const counts = { ...currentCounts, total: data.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getRegistersList = async ({ page = 1, limit = 20, ...other }) => {
  const globalState = getCurrentState();
  const currentProject = globalState.application.currentProject;
  const currentCounts = globalState.application.counts;

  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...other,
      projectUuid: currentProject?.uuid,
    });
    const data = await request(`/internal/users?${queryParams}`);
    const counts = { ...currentCounts, total: data.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getStaffRegistersList = async ({ page = 1, limit = 20, ...other }) => {
  const globalState = getCurrentState();
  const currentProject = globalState.application.currentProject;
  const currentCounts = globalState.application.counts;

  if (!currentProject) return;
  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...other,
      projectUuid: currentProject?.uuid,
    });
    const data = await staffAPIs.getStaffProfile(`?${queryParams}`);
    const counts = { ...currentCounts, total: data.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const updateUserByProjectUuid = async (
  userUuid,
  { isAccredited, isGivenMerch, isBadgePrinted, mainRoleId, quota, quotaByRole, addMemberMethod },
  isUpdateMapping = {}
) => {
  try {
    const currentProject = getCurrentState().application.currentProject;
    if (currentProject) {
      const response = await request(`/internal/projects/${currentProject.uuid}/users/${userUuid}`, {
        method: 'PATCH',
        body: {
          isAccredited,
          isGivenMerch,
          isBadgePrinted,
          mainRoleId,
          quota,
          quotaByRole,
          addMemberMethod,
        },
      });
      dispatch({
        type: constants.APPLICATIONS_UPDATE_PROFILE_INFO,
        profileInfo: response.message,
      });
      createNotification('Пользователь обновлен', 'success');
    }

    const { isUpdateDocuments } = isUpdateMapping;
    if (isUpdateDocuments) {
      const docResponse = await request(`/internal/users/${userUuid}/documents`);
      dispatch({ type: constants.APPLICATIONS_SET_DOCUMENTS, documents: docResponse.message });
    }
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getTotalCounts = async ({ projectUuid, isBezviz }) => {
  try {
    const currentProject = getCurrentState().application.currentProject;
    const statuses = isBezviz
      ? noVisaStatuses
      : currentProject
      ? accreditationStatuses
      : accreditationWithoutProjectUuidStatuses;
    const counts = await Promise.all(
      Object.values(statuses).map((status) => {
        if (status.name === 'quote') {
          return request(`/internal/projects/${projectUuid}/invitations?limit=1`).then((res) => ({
            [status.name]: res.totalCount || 0,
          }));
        }
        const queryParams = qs.stringify({
          ...status?.filterQuery,
          projectUuid: projectUuid || '',
          limit: 1,
        });

        return request(`/internal/users?${queryParams}`).then((res) => ({
          [status.name]: res.totalCount,
        }));
      })
    ).then((responses) => responses.reduce((final, c) => ({ ...final, ...c }), {}));

    dispatch({ type: constants.APPLICATIONS_GET_COUNTS, counts });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getProfileInfo = async (uuid, projectUuid = '') => {
  try {
    const [userResponse, docResponse, companiesResponse, ordersResponse] = await Promise.all([
      request(`/internal/users?uuid=${uuid}&projectUuid=${projectUuid}&limit=1`),
      request(`/internal/users/${uuid}/documents`),
      request(`/internal/companies?userId=${uuid}`),
      request(`/internal/orders?ownerId=${uuid}`),
    ]);

    dispatch({
      type: constants.APPLICATIONS_GET_PROFILE_INFO,
      profileInfo: userResponse.message[0],
      documents: docResponse.message,
      companies: companiesResponse.message,
      orders: ordersResponse.message,
    });
    return userResponse.message[0];
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getProfileInfoByEmail = async (email, projectUuid) => {
  try {
    const [userResponse] = await Promise.all([
      request(`/internal/users?email=${email}&projectUuid=${projectUuid}&limit=1`),
    ]);

    dispatch({
      type: constants.APPLICATIONS_GET_PROFILE_INFO,
      profileInfo: userResponse.message[0],
    });
    return userResponse.message[0];
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const clearProfileInfo = () => {
  dispatch({
    type: constants.APPLICATIONS_GET_PROFILE_INFO,
    profileInfo: null,
    documents: [],
    orders: [],
    companies: [],
  });
};

export const patchAccreditedUser = async ({ uuid, isAccredited, comment }) => {
  try {
    await request(`/internal/users/${uuid}`, {
      method: 'PATCH',
      body: {
        isAccredited,
        accreditationComment: comment,
      },
    });
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const patchBezvizUser = async ({ uuid, noVisa, comment }) => {
  try {
    await request(`/internal/users/${uuid}`, {
      method: 'PATCH',
      body: {
        noVisa,
        noVisaComment: comment,
      },
    });
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const editUser = async ({ uuid, ...values }) => {
  console.log('values editUser');
  console.log(values);
  try {
    const response = await request(`/internal/users/${uuid}`, {
      method: 'PATCH',
      body: values,
    });
    dispatch({
      type: constants.APPLICATIONS_UPDATE_PROFILE_INFO,
      profileInfo: response.message,
    });
    createNotification('Пользователь обновлен', 'success');
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const blockUserFromSkud = async (projectUuid, userUuid, values) => {
  try {
    const response = await userAPIs.blockBadge(projectUuid, userUuid);
    dispatch({
      type: constants.APPLICATIONS_UPDATE_PROFILE_INFO,
      profileInfo: response.message,
    });
    createNotification('Пользователь обновлен', 'success');
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const editUserByProjectUuid = async (projectUuid, userUuid, values) => {
  try {
    const response = await request(`/internal/projects/${projectUuid}/users/${userUuid}`, {
      method: 'PATCH',
      body: values,
    });
    dispatch({
      type: constants.APPLICATIONS_UPDATE_PROFILE_INFO,
      profileInfo: response.message,
    });
    createNotification('Пользователь обновлен', 'success');
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const createUser = async ({ ...values }) => {
  try {
    const data = await request(`/internal/users`, {
      method: 'POST',
      body: values,
    });
    const user = data.message;
    dispatch({ type: constants.APPLICATIONS_UPDATE_LIST, user: user });
    createNotification('Пользователь создан', 'success');
    return user;
  } catch (error) {
    createNotification(error?.message?.error || 'Что-то пошло не так', 'error');
    return false;
  }
};
export const deleteUser = async (uuid) => {
  try {
    await request(`/internal/users/${uuid}`, {
      method: 'PATCH',
      body: { isDeactivated: true },
    });
    createNotification('Пользователь удалён', 'success');
    return false;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return true;
  }
};

export const getProjects = async ({ query } = {}) => {
  try {
    const response = await request(`/projects?query=${query || ''}`);
    dispatch({
      type: constants.APPLICATIONS_GET_PROJECTS,
      projects: response.message,
    });
    return response.message;
  } catch (error) {
    console.error(error);
    createNotification('Что-то пошло не так', 'error');
    return [];
  }
};

export const getProjectsList = async ({ page = 1, limit = 15, ...other }) => {
  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...other,
    });
    const data = await request(`/projects?${queryParams}`);
    const counts = { total: data.totalCount || data.message?.length || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts,
    });
  } catch (error) {
    console.error(error);
    createNotification('Что-то пошло не так', 'error');
  }
};

export const setProject = (currentProject) => {
  getRoles(currentProject?.uuid);
  if (currentProject) {
    localStorage.setItem('project', currentProject.uuid);
  } else {
    localStorage.removeItem('project');
  }
  dispatch({
    type: constants.APPLICATIONS_SET_CURRENT_PROJECT,
    currentProject,
  });
};

export const checkCurrentProject = async (projects) => {
  const currentProjectUuid = localStorage.getItem('project');
  const currentProject = projects.find((p) => p.uuid === currentProjectUuid);
  getRoles(currentProject?.uuid);
  if (!currentProject) {
    localStorage.removeItem('project');
  } else {
    dispatch({
      type: constants.APPLICATIONS_SET_CURRENT_PROJECT,
      currentProject,
    });
  }
};

export const getCountries = async () => {
  try {
    const response = await request('/countries', { method: 'GET', isStaff: true });
    dispatch({
      type: constants.APPLICATIONS_GET_COUNTRIES,
      countries: response.message,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const setCountriesList = (countries) => {
  dispatch({ type: constants.APPLICATIONS_GET_COUNTRIES, countries: countries });
};

export const getRoles = async (projectUuid) => {
  try {
    const response = await request(`/roles?projectUuid=${projectUuid || ''}`);
    dispatch({
      type: constants.APPLICATIONS_GET_ROLES,
      roles: response.message,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const setRoles = (roles) => {
  dispatch({ type: constants.APPLICATIONS_GET_ROLES, roles: roles });
};

export const getDocumentFile = async (docUuid) => {
  try {
    return downloadFile({
      path: `/internal/documents/${docUuid}`,
      returnBlob: true,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const downloadApplicationList = async ({ page = 1, limit = 20, tab = 'processing', ...other }) => {
  const user = getCurrentState().auth.user;
  const counts = getCurrentState().application.counts;
  const currentProject = getCurrentState().application.currentProject;
  const isBezvizUser = isBezviz(user);
  let currentLimit = counts[tab] || 0;
  if (currentLimit <= 0) {
    currentLimit = 20;
  }
  try {
    const status = isBezvizUser ? noVisaStatuses[tab] : accreditationStatuses[tab];
    const queryParams = qs.stringify({
      limit: currentLimit,
      ...status?.filterQuery,
      ...other,
      projectUuid: currentProject?.uuid,
    });
    await downloadFile({
      path: `/internal/users?${queryParams}`,
      name: `ApplicationList-${getDateForFile()}.xlsx`,
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const downloadReport = async ({ reportType, format } = {}) => {
  const currentProject = getCurrentState().application.currentProject;
  if (!currentProject) {
    return null;
  }

  const accept = format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  try {
    await downloadFile({
      path: `/internal/reports?projectUuid=${currentProject.uuid}&reportType=${reportType}`,
      name: `${reportType}-${getDateForFile()}.${format}`,
      accept: accept,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const uploadReport = async (reportFile) => {
  try {
    const user = getCurrentState().auth.user;
    const type = isBezviz(user) ? 'novisa' : 'accreditation';
    const formData = new FormData();
    formData.append('fileUsers', reportFile);
    formData.append('typeFieldPatch', JSON.stringify({ type }));
    await request('/internal/users/upload', {
      method: 'PUT',
      body: formData,
    });
    createNotification('Отчёт успешно загружен', 'success');
  } catch (error) {
    createNotification('Возникла ошибка при загрузке файла', 'error');
  }
};

export const addCompany = async (body) => {
  try {
    const response = await request(`/internal/companies`, { method: 'POST', body });
    createNotification('Добавить компанию успешно', 'success');
    return response.message;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const deleteUserCompany = async (userUuid) => {
  try {
    await userAPIs.deleteUserCompany(userUuid);
    dispatch({ type: constants.APPLICATIONS_SET_COMPANIES, companies: [] });
    createNotification('Удалить компанию успешно', 'success');
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return null;
  }
};

export const getMeetingList = async ({ page = 1, limit = 20, tab = 'initial', ...other }) => {
  const currentProject = getCurrentState().application.currentProject;
  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      status: tab,
      ...other,
    });
    const data = await userAPIs.getAllMeetings(currentProject.uuid, queryParams);
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: data.message,
      counts: {},
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getTotalMeetings = async ({ projectUuid, userUuid, filters = {} }) => {
  try {
    const total = await Promise.all(
      Object.keys(MEETING_STATUS).map((status) => {
        const queryParams = qs.stringify({
          userUuid,
          status,
          ...filters,
          limit: 1,
        });
        return userAPIs.getTotalMeetings(projectUuid, queryParams);
      })
    );
    const counts = Object.keys(MEETING_STATUS).reduce(
      (p, status, index) => ({
        ...p,
        [status]: total[index]?.message || 0,
      }),
      {}
    );
    dispatch({ type: constants.APPLICATIONS_GET_COUNTS, counts });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const updateMeetingStatus = async (projectUuid, meeting, status) => {
  try {
    const list = getCurrentState().application.list;
    const counts = getCurrentState().application.counts;
    const meetingUuid = meeting.uuid;
    const oldStatus = meeting.status;
    await userAPIs.updateMeetingStatus(projectUuid, meetingUuid, { status });

    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list:
        oldStatus !== status
          ? list.filter((item) => item.uuid !== meetingUuid)
          : list.map((item) => (item.uuid !== meetingUuid ? item : { ...item, status })),
      counts:
        oldStatus !== status
          ? {
              ...counts,
              [oldStatus]: (counts[oldStatus] || 0) - 1,
              [status]: (counts[status] || 0) + 1,
            }
          : counts,
    });
    createNotification('Сохранено', 'success');
  } catch (e) {
    console.log(e);
    createNotification('Что-то пошло не так', 'error');
  }
};

export const deleteMeeting = async (projectUuid, meetingUuid, status) => {
  try {
    const list = getCurrentState().application.list;
    const counts = getCurrentState().application.counts;
    await userAPIs.deleteMeeting(projectUuid, meetingUuid);

    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: list.filter((item) => item.uuid !== meetingUuid),
      counts: { ...counts, [status]: Math.max(counts[status] - 1, 0) },
    });
    createNotification('Сохранено', 'success');
  } catch (e) {
    console.log(e);
    createNotification('Что-то пошло не так', 'error');
  }
};

export const downloadMeeting = async ({ reportType, format } = {}) => {
  const currentProject = getCurrentState().application.currentProject;
  if (!currentProject) {
    return null;
  }

  const accept = format === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  try {
    await downloadFile({
      path: `/internal/projects/${currentProject.uuid}/meetings/download`,
      name: `${reportType}-${getDateForFile()}.${format}`,
      accept: accept,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const searchUser = async (query) => {
  try {
    const queryParams = qs.stringify({ limit: 1, offset: 0, ...query });
    const data = await request(`/internal/users?${queryParams}`);
    return data.message;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return null;
  }
};

export const patchUser = async (uuid, body) => {
  try {
    await request(`/internal/users/${uuid}`, {
      method: 'PATCH',
      body,
    });
    createNotification('Сохранено');
    return true;
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
    return false;
  }
};

export const getVizitList = async ({ page = 1, limit = 20, ...other }) => {
  const currentProject = getCurrentState().application.currentProject;
  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      ...other,
    });
    const data = await projectAPIs.getVizitsList(currentProject.uuid, queryParams);
    const vizits = data.message?.vizits || [];
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: vizits,
      counts: {
        total: data.message?.totalCount ?? 0,
      },
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getOrderList = async ({ page = 1, limit = 20, projectUuid, id, ownerId, status, ...other }) => {
  const globalState = getCurrentState();
  const currentCounts = globalState.application.counts;

  let list = [];
  try {
    const queryParams = qs.stringify({
      offset: (page - 1) * limit,
      limit,
      projectUuid,
      id,
      ownerId,
      status,
      ...other,
    });
    const response = await generalAPIs.getOrders(queryParams);
    list = response.message || [];

    const counts = { ...currentCounts, total: response.totalCount || 0 };
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list,
      counts,
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const getSupportedUsers = async (query) => {
  const currentProject = getCurrentState().application.currentProject;
  try {
    const queryParams = qs.stringify({ query });
    const data = await projectAPIs.getSupportedUser(currentProject.uuid, queryParams);
    const users = data.message || [];
    dispatch({
      type: constants.APPLICATIONS_GET_LIST,
      list: users,
      counts: {
        total: users.length,
      },
    });
  } catch (error) {
    createNotification('Что-то пошло не так', 'error');
  }
};

export const setLang = (lang = LOCALIZATION.ru_RU) => {
  dispatch({ type: constants.APPLICATIONS_SET_LANG, lang });
};

export const setProjectSettings = (settings) => {
  dispatch({ type: constants.SET_PROJECT_SETTINGS, payload: settings });
};
