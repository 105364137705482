import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LanguageMuiSelect } from 'src/components/language-select';
import { LOCALIZATION } from 'src/utils/localization';
import { FIELD_TYPES, getLocalizationAnketaFieldsData } from '../AnketaAddField';
import * as yup from 'yup';
import { validatorMessage } from 'src/utils/formValidator';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectControllerHookForm } from 'src/@core/components/mui/select';
import AnketaOptionField from '../AnketaOptionField';
import { getLocalizationAnketaFields } from 'src/utils/common';
import classes from '../anketa.module.sass';
import { toast } from 'react-toastify';
import { createErrorNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';

const schema = yup.object().shape({
  name: yup.string().required(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
});

function TemplateForm({ onClose, field, onRefreshTemplates = () => {} }) {
  const anketaFieldSettings = field;

  const [isRequired, setIsRequired] = useState(false);
  const [options, setOptions] = useState([]);
  const [lang, setLang] = useState(LOCALIZATION.ru_RU);
  const [cacheValues, setCacheValues] = useState({});
  const [optionsError, setOptionsError] = useState('');

  const {
    reset,
    register,
    getValues,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        name: anketaFieldSettings?.name,
        typeInput: FIELD_TYPES[0].value,
        badgeName: '',
        defaultText: '',
        subText: '',
        hintText: '',
        length: 256,
      }),
      [field]
    ),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setIsRequired(field?.required);
    setLang(LOCALIZATION.ru_RU);
  }, [field]);

  useEffect(() => {
    setOptionsError('');
  }, [options]);

  useEffect(() => {
    reset(anketaFieldSettings);
    const options = anketaFieldSettings?.options || [];
    setCacheValues({ ...(anketaFieldSettings?.localization || {}) });
    setOptions(options);
  }, [anketaFieldSettings]);

  const handleCreateTemplate = async (body) => {
    try {
      await request(`/internal/anketas/field_templates`, {
        method: 'POST',
        body,
      });
      onRefreshTemplates();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message?.error);
    }
  };

  const handleUpdateTemplate = async (templateUuid, body) => {
    try {
      await request(`/internal/anketas/field_templates/${templateUuid}`, {
        method: 'PATCH',
        body,
      });
      onRefreshTemplates();
    } catch (error) {
      console.error(error);
      createErrorNotification(error.message?.error);
    }
  };

  const handleUpdateField = async (data) => {
    const newData = { ...field, ...data };
    const { position } = newData;

    const { optionsError, newInfoValues, localization } = getLocalizationAnketaFieldsData(
      lang,
      cacheValues,
      options,
      newData
    );
    if (optionsError) return setOptionsError(optionsError);

    const body = {
      // required: isRequired,
      position,
      name: newInfoValues.name,
      typeInput: newInfoValues.typeInput,
      length: parseInt(newInfoValues.length),
      badgeName: newInfoValues.badgeName,
      defaultText: newInfoValues.defaultText,
      subText: newInfoValues.subText,
      hintText: newInfoValues.hintText,
      options: localization[LOCALIZATION.ru_RU].options,
      localization,
    };
    if (field) {
      await handleUpdateTemplate(field.uuid, body);
    } else {
      await handleCreateTemplate(body);
    }
    setLang(LOCALIZATION.ru_RU);
    onClose();
  };

  const handleSetLang = (lang) => {
    const currentValues = Object.assign(getValues() || {}, { options });
    const currentCaches = { ...cacheValues };
    setCacheValues((old) => ({
      ...old,
      [lang === LOCALIZATION.ru_RU ? LOCALIZATION.en_US : LOCALIZATION.ru_RU]:
        getLocalizationAnketaFields(currentValues),
    }));
    const newValues = {
      ...{ typeInput: currentValues.typeInput, length: currentValues.length },
      ...getLocalizationAnketaFields(currentCaches[lang] || {}),
    };
    Object.keys(newValues).forEach((key) => setValue(key, newValues[key]));
    reset(newValues);
    setOptions(newValues.options || []);
    setLang(lang);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <form onSubmit={handleSubmit(handleUpdateField)}>
        <DialogTitle>Шаблон</DialogTitle>
        <DialogContent>
          <Stack sx={{ paddingTop: '18px' }}>
            <Grid container rowSpacing={4} columnSpacing={6}>
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="заголовок"
                  size="small"
                  {...register('name')}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <LanguageMuiSelect lang={lang} setLang={handleSetLang} />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Stack>
                  <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.primary }}>
                    Настройки поля:
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack gap="16px">
                  <Controller
                    name="typeInput"
                    control={control}
                    render={({ field }) => (
                      <SelectControllerHookForm label="специальный тип поля" fieldForm={field}>
                        {FIELD_TYPES.map((option, i) => (
                          <MenuItem key={`typeInput-${i}`} value={option.value}>
                            <>{option.label}</>
                          </MenuItem>
                        ))}
                      </SelectControllerHookForm>
                    )}
                  />

                  <AnketaOptionField
                    label="варианты выбора"
                    options={options.map((option) => ({ inputValue: option, title: option }))}
                    setOptions={(param) =>
                      setOptions((oldV) => {
                        if (typeof param === 'function') return param(oldV);
                        return param.map((option) => option.inputValue);
                      })
                    }
                    error={!!optionsError}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="имя переменной бейджа"
                    {...register('badgeName')}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack gap="16px">
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="по-умолчанию"
                    {...register('defaultText')}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="текст заглушки"
                    {...register('subText')}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    label="текст подсказки"
                    {...register('hintText')}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="number"
                    fullWidth
                    label="длина"
                    {...register('length')}
                  />
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!isRequired}
                      onChange={(e) => {
                        setIsRequired(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label="Обязательно"
                />
              </Grid> */}
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" sx={{ minWidth: '150px' }}>
            Ok
          </Button>
          <Button variant="outlined" onClick={onClose} sx={{ minWidth: '150px' }}>
            Отмена
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default TemplateForm;
