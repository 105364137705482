import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import ButtonLoading from '../button-loading';

export function ConfirmL2Modal() {
  const lang = LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    title: TEXT[lang].CONFIRM,
    content: TEXT[lang].ARE_YOU_SURE,
    okText: TEXT[lang].OK,
    cancelText: TEXT[lang].CANCEL,
    onOk: async () => { },
    onCancel: async () => { },
  });

  const handleCancel = async () => {
    setState({ show: false });
    await state.onCancel?.();
  };
  const handleShow = (data) => {
    setState({
      ...state,
      title: TEXT[lang].CONFIRM,
      content: TEXT[lang].ARE_YOU_SURE,
      okText: TEXT[lang].OK,
      cancelText: TEXT[lang].CANCEL,
      ...data,
      show: true,
    });
  };

  const handleOk = async () => {
    try {
      await state.onOk();
    } catch (e) {
      console.log(e);
    } finally {
      setState({ show: false });
    }
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_CONFIRM_L2_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_CONFIRM_L2_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <Dialog open={state.show} onClose={handleCancel}>
      <DialogTitle>{state.title}</DialogTitle>
      <DialogContent>{state.content}</DialogContent>
      <DialogActions sx={{ p: (theme) => `${theme.spacing(4)} !important` }}>
        <ButtonLoading variant="outlined" onClick={handleCancel}>
          {state.cancelText || 'Отмена'}
        </ButtonLoading>
        <ButtonLoading variant="contained" onClick={handleOk}>
          {state.okText || 'Ок'}
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmL2Modal;
