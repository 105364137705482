import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Delete } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Button, FormHelperText, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'src/@core/components/mui/select';
import { LOCALIZATION, MESSAGES } from 'src/utils/constant';
import { validatorMessage } from 'src/utils/formValidator';
import { createNotification } from 'src/utils/notifications';
import { request } from 'src/utils/request';
import * as yup from 'yup';

function ScubForm({ projectUuid, scubEvents, scubData, currentEvent, roles, notFoundSettings, refetchScubData }) {
  const [timeRanges, setTimeRanges] = useState((scubData?.ticketNumberRanges || []).map(() => uniqueId('timeRange_')));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const eventRoles = scubEvents.find((s) => s.id === currentEvent)?.roles || [];
  const lang = useSelector((state) => state.application.lang) || LOCALIZATION.ru_RU;

  const schema = yup.object().shape({
    ...roles.reduce((p, c) => {
      return {
        ...p,
        [c.id]: yup
          .number()
          .nullable(true)
          .transform((v) => v || null)
          .typeError(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD),
      };
    }, {}),
    ...timeRanges.reduce(
      (p, c) => ({
        ...p,
        [`min__${c}`]: yup
          .number()
          .typeError(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
          .positive(validatorMessage.VALUE_IS_POSITIVE)
          .test(
            'isInRange',
            validatorMessage.VALUE_IN_RANGE.replace('{min}', '1,000,000,000,000').replace('{max}', '9,999,999,999,999'),
            (value) => {
              return value >= 1000000000000 && value <= 9999999999999;
            }
          ),
        [`max__${c}`]: yup
          .number()
          .typeError(validatorMessage.PLEASE_FILL_OUT_THIS_FIELD)
          .positive(validatorMessage.VALUE_IS_POSITIVE)
          .test(
            'isInRange',
            validatorMessage.VALUE_IN_RANGE.replace('{min}', '1,000,000,000,000').replace('{max}', '9,999,999,999,999'),
            (value) => {
              return value >= 1000000000000 && value <= 9999999999999;
            }
          )
          .test('isLarger', validatorMessage.MAX_VALUE_MUST_LARGER_THAN_MIN_VALUE, (value, testContext) => {
            if (testContext.parent[`min__${c}`] > value) return false;
            return true;
          }),
      }),
      {}
    ),
  });

  const getDefaultValues = () => {
    if (!scubData) return {};
    const roleMatches = scubData.roleMatches;
    const ticketNumberRanges = scubData.ticketNumberRanges;
    let result = {};

    (roles || []).forEach((role) => {
      result = { ...result, [role.id]: roleMatches[role.id] || '' };
    });

    timeRanges.forEach((rangeId, index) => {
      result = {
        ...result,
        [`min__${rangeId}`]: ticketNumberRanges?.[index]?.min || 0,
        [`max__${rangeId}`]: ticketNumberRanges?.[index]?.max || 0,
      };
    });

    return result;
  };

  const {
    control,
    getValues,
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValues(),
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const handleUpdateScub = async (values) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      let body = { gateEventId: currentEvent };
      let objTicketNumberRanges = {};
      let roleMatches = {};
      Object.keys(values).forEach((key) => {
        if (key.includes('__')) {
          const [type, rangeId] = key.split('__');
          if (timeRanges.includes(rangeId)) {
            objTicketNumberRanges = {
              ...objTicketNumberRanges,
              [rangeId]: { ...(objTicketNumberRanges[rangeId] || {}), [type]: values[key] },
            };
          }
        } else {
          roleMatches = { ...roleMatches, [key]: values[key] };
        }
      });
      const ticketNumberRanges = Object.values(objTicketNumberRanges);
      body = { ...body, roleMatches, ...(ticketNumberRanges.length > 0 ? { ticketNumberRanges } : {}) };

      if (notFoundSettings) {
        // create
        await request(`/internal/projects/${projectUuid}/scub`, { method: 'POST', body });
        createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY, 'success');
      } else {
        // update
        await request(`/internal/projects/${projectUuid}/scub`, { method: 'PATCH', body });
        createNotification(MESSAGES[lang].UPDATE_SUCCESSFULLY, 'success');
      }
      refetchScubData();
    } catch (error) {
      console.error(error);
      createNotification('Что-то пошло не так', 'error');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(handleUpdateScub)}>
      <Stack gap="24px">
        <Stack gap="16px">
          <Typography variant="body1" fontWeight={600}>
            Сопоставить роли с уровнем доступа:
          </Typography>
          <Grid container spacing={6}>
            {roles.map((role) => (
              <React.Fragment key={role.id}>
                <Grid key={role.id} item xs={12} md={12}>
                  <Stack direction="row" gap="12px">
                    <TextField label="Роль" value={role.name?.ru_RU} fullWidth={true} />
                    <ArrowRightAltIcon fontSize="large" sx={{ height: '56px' }} />
                    <Controller
                      name={`${role.id}`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          isClearable
                          fullWidth={true}
                          label="Уровень"
                          options={eventRoles}
                          getOptionLabel={(item) => item.name}
                          getOptionValue={(item) => item.id}
                          value={field.value}
                          onChange={(value) => field.onChange(value)}
                          error={errors[`${role.id}`]}
                          errorComponent={
                            <>
                              {errors[`${role.id}`] && (
                                <FormHelperText error={true}>{errors[`${role.id}`]?.message}</FormHelperText>
                              )}
                            </>
                          }
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </React.Fragment>
            ))}
            {/* {(scubEvents.find((s) => s.id === currentEvent)?.roles || []).map((role) => (
              <Grid key={role.id} item xs={12} md={6}>
                <Stack direction="row" gap="12px" alignItems="center">
                  <TextField label="Event role" value={role.name} fullWidth={true} />
                  <ArrowRightAltIcon fontSize="large" sx={{ height: '56px' }} />
                  <Controller
                    name={`${role.id}`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        fullWidth={true}
                        label="Project role"
                        options={roles}
                        getOptionLabel={(item) => item.name?.ru_RU}
                        getOptionValue={(item) => item.id}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            ))} */}
          </Grid>
        </Stack>
        <Stack gap="16px">
          <Typography variant="body1" fontWeight={600}>
            Установить диапазоны номеров билетов (Номер билета должен состоять из 13 цифр.):
          </Typography>
          <Grid container spacing={6}>
            {timeRanges.map((range) => (
              <React.Fragment key={range}>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" gap="12px" position="relative">
                    <TextField
                      label="Min"
                      fullWidth={true}
                      type="number"
                      {...register(`min__${range}`)}
                      error={errors[`min__${range}`]}
                      helperText={errors[`min__${range}`]?.message}
                    />
                    <ArrowRightAltIcon fontSize="large" sx={{ height: '56px' }} />
                    <TextField
                      label="Max"
                      fullWidth={true}
                      type="number"
                      {...register(`max__${range}`)}
                      error={errors[`max__${range}`]}
                      helperText={errors[`max__${range}`]?.message}
                    />
                    <Stack
                      justifyContent="center"
                      sx={{ position: 'absolute', right: 0, transform: 'translateX(calc(100% + 8px))', height: '56px' }}
                    >
                      <IconButton onClick={() => setTimeRanges(timeRanges.filter((rangeId) => rangeId !== range))}>
                        <Delete />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12} md={6}>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setTimeRanges([...timeRanges, uniqueId('timeRange_')])}
              >
                <Add />
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <Grid container spacing={6} marginTop="0px">
          <Grid item xs={12} md={6}>
            <Stack direction="row" gap="16px">
              <Button variant="outlined" fullWidth onClick={() => reset()}>
                Отмена
              </Button>
              <Button type="submit" variant="contained" fullWidth disabled={isSubmitting}>
                Сохранить
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
}

export default ScubForm;
