import { Close } from '@mui/icons-material';
import { Card, CardContent, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import ProjectCard from 'src/components/project-card';
import { getLocalizationValue } from 'src/utils/common';
import { TEXT } from 'src/utils/localization';

function PreviewProjectModal({ langue, data, open, onClose }) {
  if (!data) return null;
  return (
    <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" gap="24px" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Детали проекта</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap="2rem" justifyContent="space-between" marginTop="1.25rem">
          <ProjectCard langue={langue} project={data} fullWidth={true} showProjectDetail={true} />
          <Card sx={{ borderRadius: '30px' }}>
            <CardContent sx={{ padding: '2rem 3rem' }}>
              <h2>{TEXT[langue].ABOUT_THE_EVENT}</h2>
              <div style={{ fontSize: '17px', lineHeight: '36px', hyphens: 'auto' }}>
                <div
                  style={{ whiteSpace: 'break-spaces' }}
                  dangerouslySetInnerHTML={{ __html: getLocalizationValue(data, 'comment', langue) }}
                />
              </div>
            </CardContent>
          </Card>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default PreviewProjectModal;
