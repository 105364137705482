import { Button, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/confirm-modal';
import { routes } from 'src/constants';
import { useProjectDetail } from 'src/hooks';
import UserIcon from 'src/layouts/components/UserIcon';
import UserLayout from 'src/layouts/UserLayout';
import { downloadApplicationList, getVizitList, setProject } from 'src/store/actions/application';
import VizitsList from './VizitsList';

const TABS = {
  all: {
    label: 'Все',
    name: 'all',
    filterQuery: {},
  },
  available: {
    label: 'доступны для встречи',
    name: 'available',
    filterQuery: {
      isReadyTalk: true,
    },
  },
  unavailable: {
    label: 'недоступны для встречи',
    name: 'unavailable',
    filterQuery: {
      isReadyTalk: false,
    },
  },
};

function ProjectVizitsPage() {
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isInitTotal, setIsInitTotal] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const currentProject = useSelector((state) => state.application.currentProject);
  const counts = useSelector((state) => state.application.counts);

  const projectUuid = params.uuid;
  const queryParams = qs.parse(location.search);
  const activeTab = queryParams.tab || TABS.all.name;

  const { isLoading, data } = useProjectDetail({ projectUuid: projectUuid });

  const handleGetApplicationList = useCallback(() => {
    return getVizitList({ ...queryParams, ...(TABS[activeTab]?.filterQuery || {}), page: queryParams.page });
  }, [location, currentProject]);

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(`${routes.projectVizits.path.replace(':uuid', projectUuid)}?${query}`);
    },
    [queryParams]
  );

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
    }
  }, [isLoading, data]);

  const handleSelectTab = (name) => () => {
    setQueryParams({ tab: name, page: 1 });
  };

  const downloadList = useCallback(() => {
    return downloadApplicationList(queryParams);
  }, [location]);

  useEffect(() => {
    setIsLoadingTable(true);
    handleGetApplicationList().then(() => setIsLoadingTable(false));
  }, [handleGetApplicationList]);

  const tabs = Object.values(TABS).map((s) => ({
    label: s.label,
    icon: s.icon,
    count: counts[s.name],
    isActive: activeTab === s.name,
    onSelect: handleSelectTab(s.name),
  }));

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack gap="20px">
              <Stack direction="row" gap="8px" justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h6">Компании</Typography>
              </Stack>
              <Stack direction="row" gap="8px" flexWrap="wrap">
                {tabs.map((tab, i) => (
                  <Button
                    key={`tab-${i}`}
                    variant={tab.isActive ? 'contained' : 'text'}
                    color={tab.isActive ? 'primary' : 'secondary'}
                    startIcon={<UserIcon icon={tab.icon} />}
                    onClick={tab.onSelect}
                  >
                    {tab.label}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <VizitsList
            projectUuid={projectUuid}
            loading={isLoadingTable}
            allTabs={TABS}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            onReloadTable={handleGetApplicationList}
          />
        </Card>
      </Stack>

      <ConfirmModal />
    </UserLayout>
  );
}

export default ProjectVizitsPage;
