import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment-timezone';
import qs from 'query-string';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomChip from 'src/@core/components/mui/chip';
import Loader from 'src/components/loader';
import { MUITable } from 'src/components/mui-table';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { generalAPIs } from 'src/services';
import { getOrderList } from 'src/store/actions/application';
import OrderFilters from './OrderFilters';

function ProjectOrdersPage() {
  const navigate = useNavigate();
  const { uuid: projectUuid } = useParams();
  const location = useLocation();

  const queryParams = qs.parse(location.search);
  const { page: queryPage, limit, query: querySearchText } = queryParams;
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);

  const list = useSelector((state) => state.application.list);
  const counts = useSelector((state) => state.application.counts);

  const [showFilter, setShowFilter] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const handleGetApplicationList = useCallback(async () => {
    if (!projectUuid) return;

    if (!!queryParams?.sortOrder) {
      queryParams.sortOrder = queryParams.sortOrder.toUpperCase();
    }

    return getOrderList({ page: queryParams.page, limit, projectUuid, ...queryParams }).then(() =>
      setIsLoadingTable(false)
    );
  }, [projectUuid, location]);

  useEffect(() => {
    setIsLoadingTable(true);
    handleGetApplicationList();
  }, [handleGetApplicationList]);

  const headCells = [
    {
      id: 'thezis_id',
      numeric: false,
      disablePadding: true,
      label: 'Thezis Id',
      renderRow: (row) => {
        return <Typography variant="body2">{row.thezis_id || ''}</Typography>;
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Статус',
      renderRow: (row) => (
        <CustomChip
          label={row.status === 'paid' ? 'оплачен' : 'неоплачен'}
          skin="light"
          color={row.status === 'paid' ? 'success' : 'warning'}
        />
      ),
    },
    {
      id: 'company',
      numeric: false,
      disablePadding: false,
      label: 'Компания',
      renderRow: (row) => <Typography variant="body2">{row.properties?.companyName || ''}</Typography>,
    },
    {
      id: 'method',
      numeric: false,
      disablePadding: false,
      label: 'Метод',
      renderRow: (row) => (
        <Chip
          label={row.trx_id === '' ? 'Оплата по счёту' : 'Оплата банковской картой'}
          color="secondary"
          sx={{ width: 'fit-content' }}
        />
      ),
    },
    {
      id: 'long_description',
      numeric: false,
      disablePadding: false,
      label: 'Описание',
      renderRow: (row) => <Typography variant="body2">{row.long_description || ''}</Typography>,
    },
    {
      id: 'total_sum',
      numeric: false,
      disablePadding: false,
      isSortable: true,
      label: 'Общий',
      renderRow: (row) => (
        <Stack sx={{ whiteSpace: 'nowrap' }}>
          <NumberFormat
            value={row.total_sum}
            suffix=" руб"
            decimalScale={2}
            displayType="text"
            thousandSeparator=" "
            decimalSeparator=","
          />
        </Stack>
      ),
    },
    {
      id: 'created_at',
      numeric: false,
      isSortable: true,
      disablePadding: false,
      label: 'Создан в',
      renderRow: (row) => (
        <Typography variant="body2">{moment.unix(row.created_at).format(DEFAULT_DATE_FORMAT)}</Typography>
      ),
    },
  ];

  const { reset, register, getValues, handleSubmit } = useForm({
    defaultValues: { query: queryParams?.query || '' },
  });

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(location.pathname + '?' + query);
    },
    [queryParams]
  );

  const handleSearch = ({ query }) => {
    if (query && query.trim()) {
      setQueryParams({ ...queryParams, query: query });
    } else {
      let _queryParams = queryParams;
      delete _queryParams.query;
      setQueryParams(_queryParams);
    }
  };

  const handleSyncOrders = async () => {
    try {
      setIsSyncing(true);
      await generalAPIs.syncOrders();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSyncing(false);
      setIsLoadingTable(true);
      handleGetApplicationList();
    }
  };

  const handleFilter = (filters) => {
    setQueryParams({ ...filters });
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    if (
      Object.keys(queryParams).filter(
        (param) => !['tab', 'page', 'limit', 'sortOrder', 'sortBy'].includes(param) && queryParams[param]
      ).length > 0
    ) {
      setShowFilter(true);
    }
  }, [queryParams]);

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Card>
          <CardContent>
            <Stack direction="row" flexWrap="wrap" gap="8px" justifyContent="space-between">
              <Stack direction="row" gap="8px">
                <Button
                  variant="contained"
                  startIcon={isSyncing ? <Loader size="14px" color="grey" /> : <></>}
                  disabled={isSyncing}
                  onClick={handleSyncOrders}
                >
                  Синхронизировать
                </Button>
                <Button
                  endIcon={showFilter ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                  sx={{ '& .MuiButton-endIcon': { margin: 0 } }}
                  onClick={handleToggleFilter}
                >
                  фильтр
                </Button>
              </Stack>
            </Stack>
          </CardContent>

          <Collapse in={showFilter}>
            <Box padding="0 20px" margin="16px 24px">
              <OrderFilters onSubmit={handleFilter} />
            </Box>
          </Collapse>

          <MUITable
            id="project-orders-table"
            loading={isLoadingTable}
            headCells={headCells}
            rows={list}
            rowsNum={counts['total']}
          />
        </Card>
      </Stack>
    </UserLayout>
  );
}

export default ProjectOrdersPage;
