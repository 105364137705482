import { useCallback } from 'react';
import { Pagination as RBPagination } from 'react-bootstrap';

const getStartNumber = (activeNumber) => {
  let startNumber = parseInt(activeNumber, 10) - 2;
  if (startNumber <= 1) {
    startNumber = 1;
  }
  return startNumber;
};

const getGenerateAmount = (startNumber, lastNumber) => {
  let amount = 5;
  if (lastNumber - startNumber < 5) {
    amount = lastNumber - startNumber + 1;
  }
  return amount;
};

export default function Pagination({ currentPage, totalCount, amountPerPage, onChangePage }) {
  const activeNumber = parseInt(currentPage, 10) || 1;
  const lastPage = Math.ceil(totalCount / amountPerPage);
  const startPage = getStartNumber(activeNumber);
  const generationAmount = getGenerateAmount(startPage, lastPage);
  const generateAmount = useCallback((amount, start) => {
    if (amount < 0) {
      return [];
    }
    return new Array(amount).fill(null).map((_, id) => id + start);
  }, []);
  const amount = generateAmount(generationAmount, startPage);
  if (amount.length === 0 || !totalCount) {
    return null;
  }
  return (
    <RBPagination className="d-flex justify-content-center">
      {activeNumber !== 1 && <RBPagination.First onClick={() => onChangePage(1)} />}
      {activeNumber !== 1 && <RBPagination.Prev onClick={() => onChangePage(activeNumber - 1)} />}
      {amount.map((n) => (
        <RBPagination.Item active={n === activeNumber} onClick={() => onChangePage(n)} key={n}>
          {n}
        </RBPagination.Item>
      ))}
      {activeNumber !== lastPage && (
        <RBPagination.Next onClick={() => onChangePage(activeNumber + 1)} />
      )}
      {activeNumber !== lastPage && <RBPagination.Last onClick={() => onChangePage(lastPage)} />}
    </RBPagination>
  );
}
