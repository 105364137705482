// ** MUI Imports
import MuiTimeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { reduce } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import { userAPIs } from 'src/services';
import Loader from '../loader';
import { DATE_FORMAT } from 'src/pages/project-settings/DateRangeSlider';
import { STANDARD_DATE_FORMAT, STANDARD_TIME_DATE_FORMAT, STANDARD_TIME_FORMAT } from 'src/utils/constant';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

// Styled Timeline component
const Timeline = styled(MuiTimeline)(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginLeft: theme.spacing(0.75),
  '& .MuiTimelineItem-root': {
    '&:before': {
      display: 'none',
    },
    '&:last-child': {
      minHeight: 60,
    },
  },
}));

const ACTIVITY_TYPE = {
  register: {
    render: () => `Регистрация пользователя`,
  },
  invited: {
    render: () => `Пользователь был приглашен`,
  },
  add_member: {
    render: (data) => `Пользователь добавил нового участника с электронной почтой ${data.email}`,
  },
  join_project: {
    render: (data) => `Пользователь присоединился к проекту`,
  },
  leave_project: {
    render: (data) => `Пользователь покинул проект`,
  },
  fill_anketa: {
    render: (data) => `Пользователь заполнил анкету`,
  },
  edit_profile: {
    render: (data) => `Пользователь отредактировал профиль`,
  },
  edit_anketa: {
    render: (data) => `Пользователь отредактировал анкету`,
  },
  got_ticket: {
    render: (data) => `Пользователь получил билет`,
  },
  upload_avatar: {
    render: (data) => `Пользователь загрузил аватар`,
  },
  upload_passport: {
    render: (data) => `Пользователь загрузил паспорт`,
  },
  add_vizit: {
    render: (data) => `Пользователь добавил визит`,
  },
  share_vizit: {
    render: (data) => `Пользователь поделился визитом`,
  },
  have_meeting: {
    render: (data) =>
      `Пользователь встретился с ${data.company} в ${moment(data.startTime).format(DEFAULT_DATE_FORMAT)}`,
  },
  request_meeting: {
    render: (data) =>
      `Пользователь запросил встречу с ${data.company} в ${moment(data.startTime).format(DEFAULT_DATE_FORMAT)}`,
  },
  join_program: {
    render: (data) => `Пользователь присоединился к бизнес-программе ${data.name}`,
  },
  leave_program: {
    render: (data) => `Пользователь покинул бизнес-программу ${data.name}`,
  },
  order_package: {
    render: (data) => `Пользователь заказал пакет`,
  },
};

const ACTIVITY_COLORS = ['primary', 'success', 'error', 'info', 'warning'];
const LIMIT_ROWS = 5;

function UserActivityTimeline({ userUuid, isProjectPage, isScub, isSetting }) {
  const projects = useSelector((state) => state.application.projects);
  const currentProject = useSelector((state) => state.application.currentProject);
  const projectsByUuid = reduce(projects, (prev, cur) => ({ ...prev, [cur.uuid]: cur }), {});
  const projectUuid = currentProject?.uuid;

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const fetchUserActivities = async (args) => {
    const { queryKey, pageParam = 0 } = args;
    const [_, userUuid, projectUuid, isScub, isSetting] = queryKey;
    const res = await userAPIs.getUserActivities(userUuid, pageParam, LIMIT_ROWS, projectUuid, isScub, isSetting);
    return res.message;
  };

  const { isLoading, data, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery(
    ['fetchUserActivities', userUuid, projectUuid, isScub, isSetting],
    fetchUserActivities,
    {
      enabled: isProjectPage ? !!projectUuid : !!userUuid && !!projectUuid,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.length === LIMIT_ROWS ? (allPages.length - 1) * LIMIT_ROWS + lastPage?.length : undefined;
      },
    }
  );

  const handleLoadMore = () => {
    fetchNextPage();
  };

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView]);

  if (!userUuid && !isProjectPage) return null;
  return (
    <>
      <Card>
        <Accordion defaultExpanded={isSetting}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <CardHeader sx={{ padding: 0 }} title={`Действия пользовател${isSetting ? 'ей' : 'я'}`} />
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: isSetting ? 700 : 350, overflowY: 'scroll' }}>
            {isLoading ? (
              <Loader />
            ) : data && data.pages?.[0]?.length > 0 ? (
              <Timeline>
                {data.pages.map((page, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    {page.map((activity, i) => (
                      <TimelineItem key={activity.uuid}>
                        <TimelineSeparator>
                          <TimelineDot color={ACTIVITY_COLORS[i % ACTIVITY_COLORS.length]} />
                          {(pageIndex < data?.pages?.length - 1 || i < page.length - 1) && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Box
                            sx={{
                              // mb: 2,
                              display: 'flex',
                              // flexWrap: 'wrap',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography variant="body2" sx={{ mr: 2, fontWeight: 600, color: 'text.primary' }}>
                              {activity.ownerName || ''} {activity.content || activity.type || ''}
                              {/* {ACTIVITY_TYPE[activity.type]?.render
                              ? ACTIVITY_TYPE[activity.type]?.render({
                                  ...activity.data,
                                  projectName: projectsByUuid[activity?.data?.projectUuid]?.name,
                                })
                              : activity.type} */}
                            </Typography>
                            <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                              {moment(activity.createdAt).format(STANDARD_TIME_DATE_FORMAT)}
                            </Typography>
                          </Box>
                          {/* <Typography variant="body2">
                          {ACTIVITY_TYPE[activity.type]?.render(activity.data)} в{' '}
                          {moment(new Date(activity.createdAt)).format(DEFAULT_DATE_TIME_FORMAT)}
                        </Typography> */}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </React.Fragment>
                ))}

                {isFetching ? <Loader /> : null}
                {hasNextPage ? (
                  <Box ref={ref} sx={{ margin: '16px auto' }}>
                    {/* <Button variant="text" onClick={handleLoadMore} disabled={isFetching}>
                    Загрузи больше
                  </Button> */}
                  </Box>
                ) : null}
              </Timeline>
            ) : (
              <Typography variant="caption">Нет активности</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    </>
  );
}

export default UserActivityTimeline;
