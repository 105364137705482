import { Add, ChevronLeft, Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  createFilterOptions,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { uniqueId } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DateInput from 'src/components/date-input';
import Input from 'src/components/input';
import Select from 'src/components/select';
import AvatarUpload from 'src/components/upload/AvatarUpload';
import { routes } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { generalAPIs, userAPIs } from 'src/services';
import { createUser, setProject } from 'src/store/actions/application';
import { ADD_MEMBER_METHODS_OPTIONS, DOCUMENT_TYPES } from 'src/utils/constant';
import { request } from 'src/utils/request';

const filter = createFilterOptions();

function UserCreatePage({ userUuid }) {
  const params = useParams();
  // const location = useLocation();
  // const theme = useTheme();
  const navigate = useNavigate();

  const countries = useSelector((state) => state.application.countries);
  const roles = useSelector((state) => state.application.roles);

  const [roleFields, setRoleFields] = useState([]);
  const [suggestTags, setSuggestTags] = useState([]);
  const [avatar, setAvatar] = useState('');

  const projectUuid = params.uuid;

  const fetchProjectData = async () => {
    const response = await request(`/projects?uuid=${projectUuid}`);
    return response.message?.[0];
  };

  const { isLoading, data } = useQuery('fetchProjectDetail', fetchProjectData);

  const fetchTags = async () => {
    const response = await generalAPIs.getTags();
    const raw = response.message ?? [];
    setSuggestTags(raw);
    return raw;
  };

  useQuery('fetchTags', fetchTags, { staleTime: 0 });

  useEffect(() => {
    if (!isLoading && data) {
      setProject(data);
    }
  }, [isLoading, data]);

  const handleGoBack = () => {
    navigate(routes.projectsUsers.path.replace(':uuid', projectUuid));
  };

  const uploadAvatar = async (user) => {
    if (!avatar) return null;
    try {
      const formData = new FormData();
      formData.append('fileDocument', avatar, `${user?.uuid}-avatar.png`);
      formData.append('type', DOCUMENT_TYPES.AVATAR);
      await userAPIs.uploadAvatarForMemberVizit(user?.uuid, formData);
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleSubmit = async (values) => {
    let quotaByRole = {};
    roleFields.forEach((roleId) => {
      const roleValue = values[`role-${roleId}`];
      const quotaValue = parseInt(values[`quota-${roleId}`]);
      if (roleValue && quotaValue) {
        quotaByRole = { ...quotaByRole, [roleValue]: quotaValue };
      }
    });

    const { firstName, lastName, middleName, email, phone, birthday, mainRoleId, addMemberMethod, tag } = values;

    const isCreated = await createUser({
      firstName,
      lastName,
      middleName,
      email,
      projectUserId: projectUuid,
      mainRoleId,
      phone,
      tag: tag?.inputValue ?? tag,
      birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : '',
      ...(quotaByRole ? { quotaByRole } : {}),
      ...(addMemberMethod ? { addMemberMethod } : {}),
      // accreditedBy: userUuid,
      // citizenship: values.citizenship,
      // countryResidence: values.countryResidence,
      // quota: parseInt(values.quota),
    });
    if (isCreated) {
      await uploadAvatar(isCreated);
      handleGoBack();
    }
  };

  return (
    <UserLayout showExtraNavItems={true} contentHeightFixed={true}>
      <Card sx={{ overflow: 'auto', position: 'relative' }}>
        <CardContent>
          <IconButton sx={{ position: 'absolute', left: '20px' }} onClick={handleGoBack}>
            <ChevronLeft />
          </IconButton>
          <Form onSubmit={handleSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Stack gap="16px">
                  <Typography variant="h6" align="center">
                    Добавить участника
                  </Typography>
                  <AvatarUpload avatar={avatar} setAvatar={setAvatar} />
                  <Grid container rowSpacing={5} columnSpacing={15}>
                    <Grid item xs={12} sm={12} md={7}>
                      <Stack gap="16px">
                        <Typography variant="body1" fontWeight={600}>
                          Личные данные
                        </Typography>
                        <Grid container spacing={6.75}>
                          <Grid item xs={12} sm={12} md={6}>
                            <Stack gap="20px">
                              <Input name="lastName" label="Фамилия" shrink={false} />
                              <Input name="firstName" label="Имя" shrink={false} />
                              <Input name="middleName" label="Отчество" shrink={false} />
                              <Input type="email" name="email" label="Email" shrink={false} />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <Stack gap="20px">
                              <Input name="phone" label="Телефон" shrink={false} />
                              <DateInput name="birthday" label="Дата рождения" shrink={false} />
                              {roles.length > 0 && (
                                <Select
                                  required={true}
                                  name="mainRoleId"
                                  label="Роль"
                                  options={roles}
                                  shrink={false}
                                  getOptionLabel={(item) => item.name?.ru_RU}
                                  getOptionValue={(item) => item.id}
                                />
                              )}
                              <Field
                                name="tag"
                                render={({ input, meta }) => (
                                  <Autocomplete
                                    value={input.value}
                                    onChange={(event, newValue) => {
                                      let updatedValue;
                                      if (typeof newValue === 'string') {
                                        updatedValue = { inputValue: newValue, title: newValue };
                                      } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        updatedValue = { ...newValue, title: newValue.inputValue };
                                      } else {
                                        updatedValue = newValue;
                                      }
                                      input.onChange(updatedValue);
                                    }}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.inputValue);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          title: `"${inputValue}"`,
                                        });
                                      }

                                      return filtered;
                                    }}
                                    clearIcon={<></>}
                                    options={suggestTags.map((o) => ({ inputValue: o, title: o }))}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.title;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.title}</li>}
                                    freeSolo
                                    renderInput={(params) => {
                                      return <TextField {...params} label="Тег" />;
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} sm={12} md={5}>
                      <Stack gap="16px">
                        <Typography variant="body1" fontWeight={600}>
                          квоты на добавление
                        </Typography>
                        <Stack gap="20px">
                          <Grid container spacing={4}>
                            {roleFields.map((roleId, index) => (
                              <React.Fragment key={index}>
                                <Grid item xs={7}>
                                  <Select
                                    name={`role-${roleId}`}
                                    label="Роль участника"
                                    options={roles}
                                    shrink={false}
                                    getOptionLabel={(item) => item.name?.ru_RU}
                                    getOptionValue={(item) => item.id}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Input name={`quota-${roleId}`} label="Квота" type="number" />
                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', height: 'fit-content', margin: 'auto' }}>
                                  <IconButton
                                    onClick={() => setRoleFields(roleFields.filter((role) => role !== roleId))}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                          <Button
                            variant="outlined"
                            onClick={() => setRoleFields(roleFields.concat([uniqueId('role__')]))}
                          >
                            <Add />
                          </Button>
                        </Stack>
                        <Select
                          label="Метод добавления участников"
                          options={ADD_MEMBER_METHODS_OPTIONS}
                          name="addMemberMethod"
                          getOptionLabel={(item) => item.label}
                          getOptionValue={(item) => item.value}
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="center" justifyContent="center" marginTop="12px" columnSpacing={15}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Box>
                        <Grid container spacing={6.75}>
                          <Grid item xs={12} sm={12} md={6}>
                            <Box>
                              <Button variant="outlined" fullWidth onClick={handleGoBack}>
                                Отменить
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <Button variant="contained" type="submit" fullWidth>
                              готово
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} lg={4}></Grid> */}
                  </Grid>
                </Stack>
              </form>
            )}
          </Form>
        </CardContent>
      </Card>
    </UserLayout>
  );
}

export default UserCreatePage;
